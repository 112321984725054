import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import ApiSsoFcard from './../HttpRequest/ApiFcardSso';
import Loading from '../Loading/Loading';


class ForgotForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            isSuccess: false,
            successMessage: 'Um e-mail foi enviado, verifique sua caixa de  email e acesso link para alterar sua senha',
            isError: false,
            errMessage: null,
            isLoading: false,
        };

        this.auth = this.auth.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }

    auth() {
        const client = process.env.REACT_APP_CLIENT;
        const key = process.env.REACT_APP_KEY;

        return ApiSsoFcard.post('client', {
            'client': client,
            'key': key
        });
    }

    sendEmail(token) {
        ApiSsoFcard.defaults.headers.common['Authorization'] = token;
        return ApiSsoFcard.post('user/password_forgot', {
            'email': this.state.email
        });
    }

    handleForm(e) {
        e.preventDefault();
        this.setState({isLoading: true});

        this.auth().then(authResponse => {
            this.sendEmail(authResponse.data.data.token).then(response => {
                this.setState({
                    isLoading: false,
                    isSuccess: true,
                });
            }).catch(error => {
                if (!error.response.data.data.exist) {
                    this.setState({
                        isLoading: false,
                        errMessage: 'O e-mail digitado não foi localizado, tente novamente.',
                        isError: true,
                    })
                }
            });
        }).catch(error => {

        });

    }

    componentDidMount() {
        if(localStorage.getItem('temporary_mail')){
            this.setState({
                email: localStorage.getItem('temporary_mail')
            })
        }
    }
    
    render() {
        return (
            <div className="login-page">
                <div className={this.state.isSuccess ? "login-form login-form-reset" : "login-form" }>
                    
                    <div className="login-box">
                        {this.state.isSuccess ? 
                        (
                            <div class="error-modal">
                                <div class="modal-content"> 
                                    <h2>Chute a Gol!</h2>
                                    <p className="py-4">
                                        Enviamos um e-mail para <strong>{this.state.email}</strong> com o link para você trocar sua senha. Caso não receba o e-mail, verifique sua caixa de spam ou repita o processo. 
                                    </p>
                                    <Link className="close-btn" to="/">Fechar</Link>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p className="login-form-title">Digite o e-mail cadastrado</p>
                                {this.state.isLoading ? (<Loading size="2x" />) : ''}

                                <form  onSubmit={this.handleForm} id="loginForm">
                                    <input className="login-form-input" type="text" autoComplete="off" autoFocus value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder="E-mail" />
                                    
                                    {this.state.isError ? (<p className="login-form-error-message">{this.state.errMessage}</p>) : (<></>)}

                                    <input className="login-form-submit" type="submit" value="Trocar senha"/>

                                </form>
                            </>
                        )}

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ForgotForm);