class paymentFormValidator {

    validateCardNumber(inputNum) {
        var digit, digits, flag, sum, _i, _len;
        flag = true;
        sum = 0;
        digits = (inputNum + '').split('').reverse();
        for (_i = 0, _len = digits.length; _i < _len; _i++) {
            digit = digits[_i];
            digit = parseInt(digit, 10);
            if ((flag = !flag)) {
            digit *= 2;
            }
            if (digit > 9) {
            digit -= 9;
            }
            sum += digit;
        }
        return sum % 10 === 0;
    };

    validate(verifyPlan, transactionType, cardNumber, securityCode, cardHolder, docNumber, expMonth, expYear, ) {
        
        if(verifyPlan){
                let planSelect = document.querySelector('.react-dropdown-select');

            if(!planSelect.classList.contains('active')){
                return {status: false, message: 'Selecione o plano.'};
            }
        }

        /*if(!transactionType) {
            return {status: false, message: 'Selecione o tipo de cartão.'};
        }*/

        if(this.validateCardNumber(cardNumber)){
            return {status: false, message: 'Número do cartão inválido.'};
        }
        
        if (!cardNumber) {
            return {status: false, message: 'Informe o número do cartão.'};
        }
        
        if(cardNumber.length < 19){
            return {status: false, message: 'Numéro do cartão inválido.'};
        }

        if ((expMonth > 12 || expMonth < 0) || !expMonth ) {
            return {status: false, message: 'Informe uma data de validade correta.'};
        }

        if (securityCode.length < 3 || !securityCode) {
            return {status: false, message: 'Informe o código de segurança.'};
        }

        if (cardHolder.length < 1 || !cardHolder) {
            return {status: false, message: 'Informe o nome do titular do cartão.'};
        }

        if (!docNumber) {
            return {status: false, message: 'Informe o CPF do titular do cartão.'};
        }

        if(docNumber.length < 11){
            return {status: false, message: 'Preencha um número de CPF válido.'};
        }

        return {status: true};
    }

}

export default new paymentFormValidator();