import React, { Component } from 'react';
import ForgotForm from '../../components/Password/ForgotForm';

class Forgot extends Component
{
    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.replace('/home');
        }
    }

    render() {
        return(
            <div className="forgot-page">
                <ForgotForm />
            </div>
        );
    }
}

export default Forgot;