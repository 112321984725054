import { Container, Grid } from '@material-ui/core';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, MoveOut, Sticky, StickyIn } from "react-scroll-motion";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Landing.scss'
import Logo from './logo.svg'
import VoceNoComando from './voce-no-comando.png'

import Desktop from './desktop.png'
import Notebook from './notebook.png'
import Mobile from './mobile.png'

import Bastidores from './bastidores.png'
import Treinos from './treinos.png'
import Entrevistas from './entrevistas.png'
import Coletivas from './coletivas.png'
import Campanhas from './campanhas.png'
import Jogos from './jogos.png'



class Landing extends Component
{

    componentDidMount(){
        window.addEventListener('scroll', this.onScroll);
        const header = document.querySelector('.header');
        const testLink = document.querySelector('.testLink');
        const loginLink = document.querySelector('.loginLink');
        const distY = window.scrollY
        
        if(testLink != null){
            if(distY == 0){
                header.classList.add("transparent");
            }else{
                header.classList.remove("transparent");
            }

            testLink.classList.remove('active');

        }
        
        if(testLink != null) {
            loginLink.classList.add("active");
        }
    }
    
    onScroll(){
        const testLink = document.querySelector('.testLink');
        const header = document.querySelector('.header');
        const btnMobile = document.querySelector('.Landing-btnMobile');
        const footer = document.querySelector('.footer')
        
        const footerDistance = document.documentElement.scrollHeight - footer.offsetHeight
        const windowOffset = window.offsetTop

        const distY = window.scrollY

        if(testLink != null && btnMobile != null){
            
            if(distY >= 500){
                testLink.classList.add("active");
                btnMobile.classList.add("show");
            }else{
                testLink.classList.remove("active");
                btnMobile.classList.remove("show");
            }

               
            if(distY == 0){
                header.classList.add("transparent");
            }else{
                header.classList.remove("transparent");
            }
        }

        if(btnMobile != null){
            if(distY >= (footerDistance - 1000)){
                btnMobile.classList.remove("show");
            }
        }
    }

    handleAnimation(effect) {
        const tablet = 768;
        var w = window.innerWidth;

        if(w >= tablet){
            return effect
        }else{
            return false;
        }
    }

    handleLandingAnimation(effect){
        const tablet = 768;
        var w = window.innerWidth;

        if(w >= tablet){
            return effect
        }else{
            return batch(Fade(), Sticky());
        }
    }

    replaceTitle(string){
        console.log(string)
        return string.replace('','<br />')
    }

    render() {

        return(
            <div className="Landing">
                <div className="Landing-btnMobile">
                    <Link to="/register">Teste grátis</Link>
                </div>
                <ScrollContainer>
                    <ScrollPage page={0}>
                        <Animator animation={this.handleLandingAnimation(batch(Fade(), Sticky(), MoveOut(0, -1200)))}>
                            <div className="Landing-static">
                                <Animator animation={this.handleAnimation(batch(Fade(), Sticky(), MoveOut(-300, 700)))}>
                                    <div className="align-center">
                                        <Grid container justify="flex-end" spacing={0}>
                                            <Grid item sm={12} md={8} lg={7}>
                                                <div className="animatedScenario" id="animatedScenario">
                                                    <img src={Logo} height="90" />
                                                    <h2>
                                                        A emoção do campo na sua casa.
                                                    </h2>
                                                    <h3>
                                                        Vibre de qualquer lugar a todo momento.
                                                    </h3>
                                                    <br />
                                                    <div className="animatedScenario-plans">
                                                        <h4>
                                                            Planos a partir de
                                                        </h4>
                                                        <h2>
                                                            R$ 9,90 <small>/mês</small>
                                                        </h2>
                                                        <h6>Teste grátis por 10 dias</h6>
                                                        <Link to="/register">
                                                            <a className="btn btn-primary">
                                                            TESTE GRÁTIS
                                                            </a>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Animator>                    
                            </div>
                        </Animator>
                    </ScrollPage>

                    <div className="Landing-content">
                    <ScrollPage page={1}>
                        <Container>

                            <Grid container spacing={0} alignItems="center">
                                <Grid item md={6}>
                                    <img src={VoceNoComando} />
                                </Grid>
                                <Grid item md={6}>
                                    <div className="px-5">
                                        <h1>Você no comando da sua torcida</h1>
                                        <p className="pt-3">
                                            Assista aos conteúdos preferidos do seu clube do coração ou de todos os clubes disponíveis na FutebolCard.TV<br />
                                            Curta em até 4 telas ao mesmo tempo!
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                            
                        </Container>

                        <div className="Landing-clubs">
                            <h1 className="text-center m-0">Conteúdos exclusivos</h1>
                            <p className="text-center mt-3 mb-5">Só na FutebolCard.TV você acompanha os conteúdos originais<br />em primeira mão. Entrevistas, bastidores e muito mais!</p>
                        </div>

                        <Container>
                            <Grid container xl={12} justify="center" alignItems="center">
                                <Grid container xl={7} lg={7} md={10} spacing={3} alignItems="center">
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="notebook-icon" className="img-fluid" src={Campanhas} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="mobile-icon" className="img-fluid" src={Bastidores} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="mobile-icon" className="img-fluid" src={Coletivas} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="notebook-icon" className="img-fluid" src={Entrevistas} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="desktop-icon" className="img-fluid" src={Jogos} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <img alt="desktop-icon" className="img-fluid" src={Treinos} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>

                        <div className="clubes">
                        <Container>
                            <Grid container xl={12} justify="center" alignItems="center">
                                <Grid container xl={9} lg={9} md={10} spacing={3} alignItems="center">
                                    <Grid item xs={6} sm={6} md={4}>
                                        <Link to="/register">
                                            <img alt="notebook-icon" className="img-fluid" src="img/img-tvavai.png" />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <Link to="/register">
                                            <img alt="mobile-icon" className="img-fluid" src="img/img-tvbelo.png" />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4}>
                                        <Link to="/register">
                                            <img alt="mobile-icon" className="img-fluid" src="img/img-tvcoral.png" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        </div>

                        <div className="Landing-clubs mt-5 pt-5">
                            <h1 className="text-center m-0 mb-5">Disponível nos seus dispositivos favoritos</h1>
                            <Grid container spacing={5} alignItems="center">
                                <Grid item sm={12} md={4}>
                                    <img alt="desktop-icon" src={Desktop} />
                                    <h3 class="text-center">Espelhamento na TV</h3>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <img alt="notebook-icon" src={Notebook} />
                                    <h3 class="text-center">Computador</h3>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <img alt="mobile-icon" src={Mobile} />
                                    <h3 class="text-center">Celular e Tablet</h3>
                                </Grid>
                            </Grid>
                        </div>
                    </ScrollPage>
                    </div>
                </ScrollContainer>
            </div>
        )
    }
}

export default Landing;