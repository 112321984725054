import React, { Component } from 'react';
import user  from '../../functions/user';
import './Person.scss'
import EditIcon from './edit-icon.svg'
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';

class Person extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isLoading: false,
            person: []
        }

        this.firstName = this.firstName.bind(this);
        this.lastName = this.lastName.bind(this);
    }

    componentDidMount() {

        user.getPersonData(localStorage.getItem('user')).then(response => {
            this.setState({
                person: response.data.data
            });

        }).catch(errorUser => {

        });
    }

    firstName(str){
        let firstName = str.split(' ').slice(0, -1).join(' ');
        return firstName;
    }

    lastName(str){
        var lastName = str.split(' ').slice(-1).join(' ');
        return lastName;
    }

    render() {
        return (
            <div>
                <div className="profileBox">
                    <div className="profileBox--header">
                        Detalhes da conta
                    </div>
                    <div className="profileBox--item">
                        Nome: {localStorage.getItem('name') != 'Não cadastrado' ? localStorage.getItem('name') : ''} {localStorage.getItem('last_name') != 'Não cadastrado' ? localStorage.getItem('last_name') : ''}
                    </div>
                    <div className="profileBox--item">
                       CPF: {this.state.person.document}
                    </div>
                    <div className="profileBox--item">
                        E-mail: {localStorage.getItem('email')}
                    </div>
                    <div className="profileBox--item flex">
                        <p>Alterar senha</p>
                        <Link to="/change-password"><img height="28" src={EditIcon} /></Link>
                    </div>
                    <Avatar />
                </div>

                <br />

            </div>
        )
    }

}

export default Person;