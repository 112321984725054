import React, {Component} from 'react';
import { withRouter, Link } from "react-router-dom";
import packs from '../../functions/pack';
import Loading from '../../components/Loading/Loading';
import './PackList.scss';

class PackList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            packs:  [],
        }

        this.mountImagePath = this.mountImagePath.bind(this);
    }

    async componentDidMount() {
        await packs.getAllPacks().then(response => {
            this.setState({
                packs: response.data.data,
                isLoading: false,
            });

            this.mountImagePath();
        }).catch(error => {

        });
    }

    async mountImagePath() {
        this.state.packs.map(pack => {
            pack.img = 'img/' + pack.tag +  '.png';
        });

        console.log(this.state.packs);
    }

    render() {
        return (
            this.state.isLoading ? 
                (
                    <Loading size='3x' inverse='inverse' />
                ) : (
                    <div class="min-page-size">
                    <div class="container">
                        <div class="packlist">
                            {
                                this.state.packs.map(pack => 
                                        <div class="packlist_item">
                                            <Link to={"/pack/" + pack.id}>
                                                <figure class="pb-0 mb-0">
                                                    <img src={"img/clubes/" + pack.tag + ".png"}  />
                                                </figure>
                                            </Link>
                                        </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                )

        );
    }
}

export default PackList;