import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ApiSsoFcard from './../HttpRequest/ApiFcardSso';
import './LoginForm.scss';
import Loading from '../Loading/Loading';
import { Link } from 'react-router-dom';
import loginFormValidator from '../../functions/loginFormValidator';
import Header from '../Header/Header';
import purchasedPack from '../../functions/purchasedPack';

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userSeted: false,
            user: '',
            password: '',
            isLogged: false,
            isError: false,
            errMessage: null,
            isRedirect: false,
            isLink:  false,
        };

        this.auth = this.auth.bind(this);
        this.login = this.signIn.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.setUser = this.setUser.bind(this);
        this.toRegister = this.toRegister.bind(this);
        this.checkEmailExists  = this.checkEmailExists.bind(this);
        this.handleMail = this.handleMail.bind(this);
    }

    async handleSignIn(e) {
        e.preventDefault();

        await this.signIn();
    }

    auth() {
        const client = process.env.REACT_APP_CLIENT;
        const key = process.env.REACT_APP_KEY;

        return ApiSsoFcard.post('client', {
            'client': client,
            'key': key
        });
    }

    setUser() {
        this.setState({isLoading: true});

        const validate = loginFormValidator.validateEmail(this.state.user);

        if (!validate.status)  {
            this.setState({
                isLoading: false,
                isError: true,
                errMessage: validate.message,
                isLink: false,
            });
        } else {
            this.checkEmailExists();
        }
    }

    checkEmailExists() {
        this.auth().then(authResponse => {
            ApiSsoFcard.defaults.headers.common['Authorization'] = authResponse.data.data.token;
            ApiSsoFcard.put('user/check', {
                'email': this.state.user
            }).then(response => {
                this.setState({isLoading: false});
                if (!response.data.data.exist) {
                    this.setState({
                        isError: true,
                        errMessage: 'Esse e-mail não é assinante. Você pode',
                        isLink: true
                    });
                    localStorage.setItem('temporary_mail', this.state.user);
                } else {
                    this.setState({
                        userSeted: true,   
                        isError: false,
                    });
                }
            }).catch(error => {
                this.setState({
                    isLoading: false, 
                    isError: true,
                    errMessage: error.response.data.data.message,
                    isLink: false,
                });
            });
        })
    }

    signIn() {
        this.setState({isLoading: true});
        console.log('Acessou o signin');

        this.auth().then(authResponse  => {
            const {user, password} = this.state;

            ApiSsoFcard.defaults.headers.common['Authorization'] = authResponse.data.data.token;
            ApiSsoFcard.post('user/sign_in', {
                'user': user,
                'password': password,
            }).then((response) => {

                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('name', response.data.data.people.first_name);
                localStorage.setItem('last_name', response.data.data.people.last_name);
                localStorage.setItem('email', response.data.data.email);
                localStorage.setItem('account', response.data.data.account);
                localStorage.setItem('user', response.data.data.user);
                localStorage.setItem('document_type', response.data.data.document_type);
                localStorage.setItem('photo', response.data.data.photo);

                purchasedPack.getPurchasedPack().then(response => {

                    console.log(response.data.data.status)

                    if(response.data.data.status === 'canceled') {
                        this.props.history.push("/reactivate");
                        window.location.reload();
                        Header.forceUpdate();

                        return;
                    }

                    if(localStorage.getItem('name')){
                        this.props.history.push("/home");
                        window.location.reload();
                        Header.forceUpdate();
                    }else{
                        this.props.history.push("/contract");
                        window.location.reload();
                        Header.forceUpdate();
                    }

                }).catch(error => {


                });

                this.setState({isLoading: false});

                // if(localStorage.getItem('name')){
                //     this.props.history.push("/home");
                //     window.location.reload();
                //     Header.forceUpdate();
                // }else{
                //     this.props.history.push("/contract");
                //     window.location.reload();
                //     Header.forceUpdate();
                // }

            }).catch((error) => {
                this.setState({isLoading: false});
                this.setState({isError: true});
                this.setState({errMessage: 'Senha inválida, tente novamente.'});
                
            });
        })
    }

    toRegister() {
        this.props.history.push("/register");
    }

    handleMail(e){
        this.setState({user: e.target.value})
        localStorage.setItem('temporary_mail', e.target.value);
    }

    componentDidMount() {
        if (localStorage.getItem('r-email')) {
            this.setState({
                user: localStorage.getItem('r-email'),
                userSeted: true,
                isError: true,
                errMessage: 'Esse e-mail já está cadastrado. Digite sua senha para entrar.',
                isRedirect: true,
             });
                        
            localStorage.removeItem('r-email');
        }
    }
    
    render() {

        if(this.state.userSeted){
            document.querySelector('.testLink').classList.remove('active')
        }

        return (
            <div className="login-form">
                
                <div className="login-box">
                    <p className="login-form-title">Digite seu e-mail</p>
                    {this.state.isLoading ? (<Loading size="2x" />) : ''}

                    <form  onSubmit={this.state.userSeted ? this.handleSignIn : (e) => e.preventDefault()} id="loginForm">
                        <input className="login-form-input" type="text" autoComplete="off" autoFocus value={this.state.user} onChange={(e) => this.handleMail(e)} placeholder="E-mail" />
                        {this.state.userSeted ? 
                            (<input className="login-form-input" type="password" autoComplete="off" autoFocus value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} placeholder="Senha" /> ): ('')}
                        
                        {this.state.isError ? 
                            (
                                <p className="login-form-error-message">
                                    {this.state.errMessage} {this.state.isLink ? (<><Link to="/register">TESTAR GRÁTIS</Link> agora.</>) : (<></>)}
                                </p>
                            ) : (
                                <></>
                            )}

                        {!this.state.userSeted ?
                        (<button type="button" className="login-form-submit" onClick={this.setUser} >Continuar</button>) :
                        (<input className="login-form-submit" type="submit" value="Entrar"/>) }

                    </form>
                    
                    {this.state.userSeted ?
                        (
                            <p  className="login-sign-in"><Link className="login-link-sign-in" to="/forgot">Esqueceu a senha?</Link> </p>
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(LoginForm);