import React, { Component } from 'react';
import user  from '../../functions/user';
import Loading from '../../components/Loading/Loading';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async validateToken() {
        return await user.tokenValidate();
    }

    componentDidMount() {
        this.validateToken().then(response => {
            this.setState({
                isLoading: false
            })
        }).catch(error => {
            this.props.history.replace('/login');
        });
    }



    render() {
        return(
            <div className="login-page">
                {this.state.isLoading ? 
                    (
                        <Loading size="2x" inverse="inverse"/>
                    ) : (
                        <ChangePasswordForm />
                    )
                }
            </div>
        )
    }

}

export default ChangePassword;