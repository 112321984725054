import React, { Component } from 'react';
import './UpgradePackForm.scss'
import { withRouter } from 'react-router-dom';
import Loading from '../Loading/Loading';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import purchasedPack from '../../functions/purchasedPack';
import pack from '../../functions/pack';
import user from '../../functions/user';
import toastr from 'toastr';
import CancellationModal from '../CancellationModal/CancellationModal';


class UpgradePackForm extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
            isLoading: false,
            isError: false,
            errorMessage: null,
            updateSuccess: false,
            updateAllowed: true,
            contract: 'Alterar o plano para o plano FutebolCard.TV que dá acesso a todos os videos dos clubes da plataforma',
            modalTitle: 'Substituição!',
            actualPlan: [],
            newPlan: [],
            closeButtomClass: "register-form-close",
            closeButtomText: "Cancelar troca de plano",
            cancellationModal: false,
        };
        
        this.handleContract = this.handleContract.bind(this);
        this.handleClose  = this.handleClose.bind(this);
        this.handleCloseButtom = this.handleCloseButtom.bind(this);
        this.switchPack = this.switchPack.bind(this);
    }

    async getPackByTag(tagName) {
        return await pack.getPackByTag(tagName);
    }

    async getAccountPurchasedPack() {
        return await user.getPurchasedPack();
    }

    async validatePurchasedPackUpdate() {
        return await purchasedPack.validatePurchasedPackUpdate();
    }

    async handleContract() {
        await Promise.all([this.getPackByTag('fctv'), this.getAccountPurchasedPack(), this.validatePurchasedPackUpdate()]).then(responses => {
            console.log(responses[1].data);
            this.setState({
                newPlan: responses[0].data.data,
                actualPlan: responses[1].data.data
            });
        }).catch(error => {
            console.log(error.response.data.data.message);
            this.setState({
                modalTitle: 'Na Trave!',
                updateAllowed: false,
                errorMessage: error.response.data.data.message,
                closeButtomClass: "register-form-submit",
                closeButtomText: "Fechar",
            });
        })


        this.setState({
            open: true
        });

        // Modal
    }

    switchPack(){
        this.setState({isLoading: true});
        purchasedPack.changePurchasedPack().then(response => {
            this.setState({
                isLoading: false,
                updateSuccess: true,
                open: false
            });

            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
            toastr.clear();
            setTimeout(() => toastr.success(`Parabéns!! Agora você pode curtir a emoção de todo conteúdo no FutebolCard.TV.`), 300);

            this.props.history.push('/home');

        }).catch(error => {
            console.log(error.response);
            this.setState({
                isLoading: false,
                isError: true,
                errorMessage: error.response.data.data.message,
                open: false
            });

            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
            toastr.clear()
            setTimeout(() => toastr.success(`${this.state.errorMessage}`), 300)
            
        });
    }

    handleClose() {
      this.setState({
          open: true
      });
    }

    handleCloseButtom() {
        this.setState({open: false});
        this.props.history.push('account');
    }

    componentDidMount() {

    }

    render(){
        return(
            <div className="register-form">
                    {this.state.isLoading ? 
                    (
                        <Loading />
                    ) : (
                        <div>


                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className="error-modal"
                                open={this.state.open}
                                onClose={this.handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                timeout: 500,
                                }}
                            >
                                <Fade in={this.state.open}>
                                    <div className="modal-content">
                                        <h2 id="transition-modal-title">{this.state.modalTitle}</h2>
                                        <p id="transition-modal-description">
                                            {this.state.updateAllowed ? 
                                            (
                                                <> Você confirma a troca do plano {this.state.actualPlan.name} no valor de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.actualPlan.value)} pelo plano completo {this.state.newPlan.name} no valor de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.newPlan.value)}? </>
                                            ) : (
                                                this.state.errorMessage
                                            )}
                                            
                                        </p>
                                        {this.state.updateAllowed  ? (
                                            <button className="register-form-submit" onClick={this.switchPack}>Confirmo a troca de plano</button>
                                        ) : (
                                            <></>
                                        )}
                                        
                                        <button className={this.state.closeButtomClass} onClick={this.handleCloseButtom}>{this.state.closeButtomText}</button>
                                    </div>
                                </Fade>
                            </Modal>
                            <div className="register-box">
                                <div className="register-form-title"> Troca de plano </div>
            
                                <div className="register-contract-box-pack text-center">
                                    Você pode realizar um upgrade em seu perfil.
                                    <br /><br /><br />
                                    Com o plano FutebolCard.TV você tem direito a assistir todo conteúdo de vídeos de todos os clubes por apenas R$ 49,90
                                </div>
                                <button className="register-form-submit" type="button" onClick={this.handleContract}>Trocar plano</button>
                                <CancellationModal />
                            </div> 

                        </div>
                    )}

            </div> 
        )          
    }
}

export default withRouter(UpgradePackForm);