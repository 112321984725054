import './Ops.css';

function Ops() {
    return (
        <div className="col-12 text-center ops-container">
            <h1 className="ops-title">Pra foora!!!!</h1>
            <h3 className="ops-subtitle">Tente novamente</h3>
        </div>
    );
  }

  export default Ops;