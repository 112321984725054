import React, {Component} from 'react';
import ChangePaymentForm from '../../components/ChangePaymentForm/ChangePaymentForm';
import user  from '../../functions/user';
import purchasedPack from '../../functions/purchasedPack';
import Loading from '../../components/Loading/Loading';

class ChangePayment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }
    
    render() {
        return(
            <div className="payment-page">
                {this.state.isLoading ? 
                    (
                        <Loading size='3x' inverse='inverse' />
                    ) : (
                        <ChangePaymentForm />
                    )
                }   
            </div>
        );
    }
}

export default ChangePayment;