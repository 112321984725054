import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Loading from '../Loading/Loading';
import './RegisterForm.scss';
import ApiSsoFcard from '../HttpRequest/ApiFcardSso';
import registerFormValidator from '../../functions/registerFormValidator';

import '../Password/Password.scss'

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            userSeted: false,
            title: 'Digite seu e-mail',
            step: 1,
            user: '',
            password: '',
            allow_email: false,
            password_repeat: '',
            isLogged: false,
            isError: false,
            errMessage: null,
            formFilled: false,
            passwordStrength: '',
        };

        this.handleSignUp = this.handleSignUp.bind(this);
        this.setUser = this.setUser.bind(this);
        this.auth = this.auth.bind(this);
        this.signUp = this.signUp.bind(this);
        this.signIn = this.signIn.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }

    componentDidMount(){
        const header = document.querySelector('.header');
        const testLink = document.querySelector('.testLink');
        const loginLink = document.querySelector('.loginLink');

        if(testLink != null) {
            header.classList.remove('transparent');
            testLink.classList.remove('active');
            loginLink.classList.add('active');
        }

        if(localStorage.getItem('temporary_mail') != null){
            this.setState({
                user: localStorage.getItem('temporary_mail')
            });
            localStorage.removeItem('temporary_mail')
        }

    }

    handleSignUp(e) {
        e.preventDefault();

        this.setState({ isLoading: true });

        const validate = registerFormValidator.validate(this.state.user, this.state.password, this.state.password_repeat);

        if (!validate.status) {
            this.setState({
                isLoading: false,
                isError: true,
                errMessage: validate.message
            });
            
        } else {
            this.signUp();
        }

    }

    auth() {
        const client = process.env.REACT_APP_CLIENT;
        const key = process.env.REACT_APP_KEY;

        return ApiSsoFcard.post('client', {
            'client': client,
            'key': key
        });
    }

    setUser() {
        this.setState({isLoading: true});

        const validate = registerFormValidator.validateLogin(this.state.user);

        if (!validate.status) {
            this.setState({
                isLoading: false,
                isError: true,
                errMessage: validate.message
            });
            
        } else {
            this.auth().then(authResponse => {
                ApiSsoFcard.defaults.headers.common['Authorization'] = authResponse.data.data.token;
                ApiSsoFcard.put('user/check', {
                    'email': this.state.user
                }).then(response => {
                    this.setState({isLoading: false});
    
                    if (!response.data.data.exist) {
                        this.setState({
                            isError: false,
                            userSeted: true,
                            step: 2,
                            title: 'Crie uma senha',       
                        });
                        const loginLink = document.querySelector('.loginLink');

                        if(loginLink != null){
                            loginLink.classList.remove('active');
                        }
                    } else {
                        localStorage.setItem('r-email', this.state.user);
                        this.props.history.push("/login");
                    }
        
                }).catch(error => {
                    this.setState({
                        isLoading: false, 
                        isError: true,
                        errMessage: error.response.data.data.message,
                    });
                });
    
            }).catch(error => {
                this.setState({isLoading: false});
                this.setState({isError: true});
                this.setState({errMessage: 'Falha de comunicação tente novamente mais tarde!'});
            });
        }
    }

    signUp() {
        this.auth().then(authResponse => {
            const {user, password} = this.state;
            console.log(authResponse.data.data.client);
            ApiSsoFcard.defaults.headers.common['Authorization'] = authResponse.data.data.token;
            ApiSsoFcard.post('user/sign_up', {
                'email': user,
                'password': password,
                'client_id': authResponse.data.data.client,
                'allow_email': this.state.allow_email == 'on' ? true : false,
                'full_sign_up': false
            }).then(response => {
                localStorage.setItem('account', response.data.data.account);
                this.signIn(authResponse.data.data.token)
            }).catch(error => {
                let hasEmailError       = error.response.data.errors.hasOwnProperty('email');
                let hasPasswordError    = error.response.data.errors.hasOwnProperty('password');
                let message = null;
    
                if (hasEmailError) {
                    message = error.response.data.errors.email[0];
                } else if (hasPasswordError)  {
                    message = error.response.data.errors.password[0];
                }
    
                this.setState({
                    isLoading: false,
                    isError: true,
                    errMessage: message
                });
            });

        }).catch(error => {
            this.setState({isLoading: false});
            this.setState({isError: true});
            this.setState({errMessage: 'Falha de comunicação tente novamente mais tarde!'});
        });
    }

    handlePassword(event){
        this.setState({password: event.target.value});
        var score = 0
        var pass = event.target.value

        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i=0; i<pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        if (score > 80)
            return this.setState({passwordStrength: "pw-str strong"});
        if (score > 60)
            return this.setState({passwordStrength: "pw-str good"});
        if (score >= 30)
            return this.setState({passwordStrength: "pw-str weak"});
    
        return this.setState({passwordStrength: ""});;
    }

    signIn(token) {
        const {user, password} = this.state;

        ApiSsoFcard.defaults.headers.common['Authorization'] = token;
        ApiSsoFcard.post('user/sign_in', {
            'user': user,
            'password': password,
        }).then((response) => {
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('name', response.data.data.people.first_name);
            localStorage.setItem('last_name', response.data.data.people.last_name);
            localStorage.setItem('email', response.data.data.email);
            localStorage.setItem('user', response.data.data.user);
            localStorage.setItem('document_type', response.data.data.document_type);
            localStorage.setItem('photo', response.data.data.photo);
            this.props.history.push("/contract");
            
        }).catch((error) => {
            console.log(error.response);
            this.setState({isLoading: false});
            this.setState({isError: true});
            this.setState({errMessage: 'Login e/ou senha inválidos'});
        });
    }
    
    render() {
        return (
            <div className="register-form">
                <div className="register-box">
                    <step className="register-step">Passo {this.state.step} de 4</step> <br />
                    <div className="register-form-title"> {this.state.title} </div>

                    {this.state.isLoading ? 

                    (<Loading/>) :
                     
                    (
                        <div>
                            <form  onSubmit={this.state.userSeted ? this.handleSignUp : (e) => e.preventDefault()} id="loginForm">

                                <input className="register-form-input" type="text" autoComplete="off" disabled={this.state.userSeted ? "true" : "" } autoFocus value={this.state.user} onChange={(e) => this.setState({user: e.target.value})} placeholder="E-mail" />
                                
                                {this.state.userSeted ? 
                                    (
                                        <>
                                            <input className="login-form-input" type="password" autoComplete="off" autoFocus value={this.state.password} onChange={(e) => this.handlePassword(e)}  placeholder="Senha" />
                                            <input className="login-form-input" type="password" autoComplete="off" value={this.state.password_repeat} onChange={(e) => this.setState({password_repeat: e.target.value})} placeholder="Confirme sua senha" /> 
                                            <div className={this.state.passwordStrength}></div>
                                            <div className="pw-type">
                                                <div>Baixa</div>
                                                <div>Média</div>
                                                <div>Alta</div>
                                            </div>
                                        </>
                                    ) : 
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                {!this.state.userSeted ? 
                                    (    
                                        <div class="checkbox-control mb-3">
                                            <input type="checkbox" className="register-checkbox" id="register-checkbox" onChange={(e) => this.setState({allow_email: e.target.value})}/> 
                        
                                            <label className="register-texts" for="register-checkbox">
                                                Quero receber informações da FutebolCard.TV e seus parceiros.
                                            </label>       
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }

                                {this.state.userSeted ? 
                                    (
                                        <p className="register-texts text-center px-4">
                                            A senha deve ter no mínimo 8 caracteres contemplando ao menos uma letra minúscula, uma maiúscula e um número.
                                        </p>
                                    ) : (
                                        <></>
                                    )
                                }

                                {this.state.isError ? (<p className="login-form-error-message">{this.state.errMessage}</p>) : (<></>)}

                                {!this.state.userSeted ?
                                    (
                                        <button className="register-form-submit" type="button" onClick={this.setUser}>Continuar</button>
                                    ) : (
                                        <input className="register-form-submit" type="submit" value="Continuar"/>
                                    )
                                }

                            </form>
                            {!this.state.userSeted ?
                                (
                                    <p className="register-texts text-center px-4">
                                        A FutebolCard.TV usará seus dados para oferecer e dar suporte aos seus serviços, 
                                        personalizar e melhorar sua experiência no ambiente digital e para enviar informações. 
                                        Você pode alterar as preferências de comunicação quando quiser. Ao clicar em "Continuar" você confirma que leu nossas 
                                         <Link to="legal-terms" className="link"> Politicas de Privacidade</Link>
                                    </p>
                                ) : (
                                    <></>
                                )
                            }   
                        </div>
                    )}

                    
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterForm);