import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';
import './Contact.scss';
import Central from '../../components/Central/Central';

class Contact extends Component
{
    componentDidMount() {
    
    }

    openNeoAssist(){
        Central.expand();
    }

    render() {
        return(
            <div className="login-page">
                <div className="login-form">
                    <div className="login-box contact-box text-center">
                        <p className="login-form-title">Fale com a FutebolCard.TV</p>

                        <div>
                            <EmailIcon htmlColor="#707070" /> contato@futebolcard.tv
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;