import React, {Component} from 'react';
import user  from '../../functions/user';
import pack from '../../functions/pack';
import CategoryCaorousel from '../../components/CategoryCarousel/CategoryCarousel';
import Loading from '../../components/Loading/Loading';

class Pack extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            cardsList: [],
        }

    }

    async getPlaylists() {
        return await pack.getPlayListsValidated(this.props.match.params.id)
    }

    async componentDidMount() {
        user.tokenValidate()
        .then(response => {
            return;
        })
        .catch(error => {
            this.props.history.replace('/login')
        })

        this.getPlaylists().then(response => {
            this.setState({cardsList: response.data.data});

            console.log(response.data.data[0].playlist);
        }).catch(error => {

        });
    }

    render() {
        return (
            <div className="home-box">
                {this.state.cardsList.map(card => {
                    return <CategoryCaorousel playList={card.playlist} purchased={card.is_purchased}/>
                })}
            </div>
        );
    }
}

export default Pack;