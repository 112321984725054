import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { Container } from '@material-ui/core';
import './LegalTerms.scss'

import { useEffect } from "react";

function ScrollToTopOnMount() {
useEffect(() => {
    window.scrollTo(0, 0);
}, []);

return null;

}

class LegalTerms extends Component
{
    render(){
        return(
            <div className="LegalTerms" id="1">
                <ScrollToTopOnMount />

                <Container>
                    <div className="title">
                        <h1>AVISOS LEGAIS</h1>
                    </div>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                        <Tab eventKey="home" title="Política de Privacidade">
                            TERMOS DE USO<br /><br />
                            Atualizado em: 20 de novembro de 2020.<br /><br />
                            NOSSO COMPROMISSO COM A PRIVACIDADE<br /><br />
                            No Portal FutebolCard, estamos comprometidos com a proteção de sua privacidade on-line. Nossos esforços visam proteger quaisquer informações pessoais que você nos forneça, e fazer todos os esforços possíveis para utilizar tais informações somente de acordo com o que você decidir e que seja necessário. O objetivo desta Política de Privacidade é informar nossas práticas de privacidade on-line e descrever as opções de que você dispõe sobre o meio como suas informações são coletadas e utilizadas. Esta Política pode ser acessada através da parte inferior de cada página de cada site ao qual ela se aplica, e em qualquer ponto onde informações pessoais são solicitadas. Também explica as medidas de segurança tomadas para proteger suas informações, o acesso que você tem às suas informações e quem você poderá contatar no portal FutebolCard para responder às suas dúvidas sobre esta Política de Privacidade e solucionar quaisquer problemas que possam surgir.<br /><br />
                            INFORMAÇÕES PESSOAIS<br /><br />
                            Ao visitar o portal FutebolCard ou qualquer de seus anúncios interativos, o portal FutebolCard não coleta qualquer informação pessoal, como nome, endereço, telefone, a menos que você, voluntariamente informe seus dados pessoais nos campos especificamente designados para esta informação. Se você optar por não fornecer seus dados, você não constará do banco de dados do portal FutebolCard, e portanto não poderá efetuar compras de produtos e/ou serviços, bem como deixará de receber informações sobre lançamentos de produtos e promoções. Você fornecerá voluntariamente no portal FutebolCard as suas informações pessoais on-line quando: Você fizer pedido de produtos ou serviços no portal FutebolCard Você nos envia perguntas ou comentários Você solicita informações ou materiais Você solicita serviços e suporte de garantia ou pós-garantia para um produto no portal FutebolCard Você participa de pesquisas on-line Você participa de promoções, prêmios, sorteios ou concurso Essas informações a serem fornecidas voluntariamente, poderão incluir nome, endereço, endereço de e-mail e informações do cartão de crédito na hora da compra. O portal FutebolCard utiliza as informações pessoais que você fornece voluntariamente, para inscrever você em programas; responder a seus e-mails; enviar as informações solicitadas; e enviar produtos e serviços adquiridos através do site. Também utilizamos essas informações pessoais para lhe dar informações referentes à sua conta e aos produtos e serviços que você comprou de nós, para entender melhor suas necessidades e interesses, melhorar nosso serviços e personalizar as comunicações.<br /><br />
                            E-MAIL<br /><br />
                            Após fornecer voluntariamente suas informações pessoais, o portal FutebolCard poderá lhe enviar e-mail para entrar em contato sobre as suas compras efetuadas, e para resposta às suas perguntas. O portal FutebolCard também poderá enviar-lhe e-mail com informações e/ou ofertas especiais sobre produtos e serviços que possam ser de seu interesse, salvo se você indicar que não deseja recebê-los . Nós lhe daremos oportunidade de nos informar sua preferência quanto a receber e-mail promocional ao se registrar para um serviço, quando nos der suas informações pessoais, ou quando nos enviar seu e-mail. Caso decida não aceitar esses e-mails promocionais, você deixará de receber ofertas especiais que talvez lhe sejam úteis. Em cada e-mail promocional que você receber do portal FutebolCard encontrará informações para a suspensão de envio de novos e-mails promocionais. Para obter mais informações sobre como suspender o recebimento de e-mails do portal FutebolCard.<br /><br />
                            COMPARTILHANDO SUAS INFORMAÇÕES PESSOAIS<br /><br />
                            O portal FutebolCard não venderá, alugará ou comercializará as informações pessoais que você fornecer on-line a terceiros, inclusive informações de meios de pagamento. Ao preencher seus dados pessoais, você também estará autorizando o portal FutebolCard a compartilhar suas informações pessoais com determinadas afiliadas comerciais no portal FutebolCard ou outras empresas do mesmo nível, na medida em que seja necessário para o envio de produtos ou serviços e para efetuar a cobrança destes valores. Você poderá solicitar a qualquer momento, que seus dados pessoais sejam alterados ou retirados de nosso banco de dados, através do e.mail, ou com o SAC Serviço de Atendimento ao Consumidor. O portal FutebolCard poderá revelar informações pessoais a terceiros sem o seu consentimento, conforme exigido por lei ou ordem judicial, para cooperar com autoridades governamentais em investigações criminais e para executar ou proteger os termos de serviço do portal FutebolCard. O portal FutebolCard se reserva o direito de transferir suas informações pessoais em caso de venda total ou parcial de nossa empresa ou nossos ativos. Se a empresa for vendida ou transferida a terceiros, o portal FutebolCard lhe dará oportunidade de optar por não transferir suas informações a tais terceiros. Em alguns casos, isso poderá significar que os terceiros não conseguirão continuar a lhe fornecer os serviços ou produtos que no portal FutebolCard fornecia.<br /><br />
                            OUTRAS INFORMAÇÕES<br /><br />
                            O portal FutebolCard recebe e registra automaticamente dados sobre nossos servidores a partir dos browsers do visitante, incluindo endereços IP (Protocolo da Internet) de computador e outras informações fornecidas através do uso de cookies. Essas informações são coletadas sobre milhares de visitas de site e analisadas como um todo. Essa análise procura tendências entre muitos visitantes do portal FutebolCard e determina quais partes do site são acessadas com maior freqüências e quais informações os visitantes consideram mais valiosas. Também podemos coletar e registrar informações sobre o que você viu em nosso website. Podemos utilizar esse tipo de informação e combiná-la com suas informações pessoais para ajudar a personalizar nossas futuras interações com você. Ao fazer isso, esperamos fornecer melhores serviços a você moldando nossas comunicações para atenderem a seus interesses - dar-lhe mais do que você quer e menos do que você não quer. Contudo, sempre lhe daremos oportunidade de optar por permitir ou não permitir que usemos esse tipo de informações em comunicações futuras.<br /><br />
                            PROTEGENDO SUAS INFORMAÇÕES<br /><br />
                            Para evitar acesso não autorizado, manter a precisão dos dados e assegurar o uso correto das informações, instalamos determinados procedimentos físicos, eletrônicos, gerenciais e de segurança para proteger e manter seguras as informações que coletamos on-line. Protegemos as informações de acordo com as normas e procedimentos de segurança estabelecidos, tais como uso de Secure Socket Layer (SSL), e continuamente avaliamos novas tecnologias para proteção de informações. Os funcionários do Portal FutebolCard são treinados para entender e cumprir esses princípios de informação e comunicamos nossa política, prática e instruções de privacidade a todos os funcionários. No entanto, apesar de nossos esforços para proteger suas informações pessoais, você também deverá tomar providências para proteger suas informações. Sugerimos que você tome todas as precauções para proteger suas informações pessoais enquanto estiver na Internet. No mínimo, incentivamos que você mude sua senha com freqüência, utilizando uma combinação de letras e números, e certifique-se de estar usando um browser seguro ao navegar pela Internet. Para maiores informações sobre como você poderá se proteger on-line, visite o website da Privacy Leadership Initiative www.understandprivacy.com<br /><br />
                            COMUNIDADES<br /><br />
                            Alguns de nossos sites poderão capacitá-lo a participar de serviços abertos ao público, tais como conselhos de debates, chats e eventos ao vivo. Se você, voluntariamente decidir por participar, favor usar de discrição ao colocar suas informações pessoais quando utilizar esses serviços. Esteja ciente de que ao revelar informações pessoais nesses sites, tais como seu nome, nome de membro, endereço de e-mail, etc., essas informações poderão ser coletadas e utilizadas por outras pessoas para enviar e-mails não solicitados. Os serviços são abertos ao público, e o que você colocar lá poderá ser visto por qualquer um e não estará protegido. O Portal FutebolCard não pode controlar os comentários que você poderá receber enquanto participar desses serviços. Você talvez considere os comentários de outras pessoas ofensivos, prejudiciais ou imprecisos.<br /><br />
                            LINKS EXTERNOS<br /><br />
                            Alguns Websites no portal FutebolCard contêm links de/para outros websites e o portal FutebolCard não se responsabiliza pelas práticas de privacidade de tais websites. O portal FutebolCard incentiva você a verificar as práticas de privacidade de tais web sites.<br /><br />
                            MANTENDO SUAS INFORMAÇÕES ATUALIZADAS<br /><br />
                            Se você for um usuário voluntariamente registrado no Portal FutebolCard ou de qualquer outro serviço on-line do Portal FutebolCard e houver qualquer alteração em suas informações pessoais, você poderá revisar e atualizar seu perfil de membro através de seu nome de usuário e senha. Você também tem a opção de enviar um e-mail para solicitar alteração de suas informações ou uma cópia das informações que coletamos on-line sobre você. Faremos todos os esforços para nos certificarmos de que suas solicitações sejam atendidas o mais breve possível. Para proteger sua privacidade, uma prova de identidade é necessária.<br /><br />
                            CONTATOS NO PORTAL FUTEBOLCARD<br /><br />
                            Em caso de dúvida ou preocupação com sua privacidade ao utilizar o Portal FutebolCard, favor entrar em contato conosco através do e-mail: O Departamento responsável pela Política de Privacidade On-line do Portal FutebolCard trabalhará junto com você para esclarecer qualquer preocupação ou dúvida que você tenha sobre esta política.<br /><br />
                            ALTERAÇÕES DESTA DECLARAÇÃO DE PRIVACIDADE<br /><br />
                            O Portal FutebolCard se reserva o direito de modificar esta declaração de privacidade de tempos em tempos, colocando um anúncio em destaque nesta página ou, em caso de alteração substancial, notificando por e-mail todos os clientes cujas informações retivemos.<br /><br />
                            CRIANÇAS - AVISO AOS PAIS<br /><br />
                            O Portal FutebolCard não pretende coletar dados de menores. Recomendamos que os menores que visitam o site do Portal FutebolCard peçam autorização de seus pais antes de enviarem qualquer informação pessoal. É aconselhável que os pais supervisionem o uso que seus filhos fazem do computador, e na hipótese de qualquer menor enviar informações pessoais o Portal FutebolCard, seus pais ou responsáveis poderão contatar o Portal FutebolCard, através dos endereços acima descritos, solicitando a retirada dessas informações de nosso banco de dados.<br /><br />
                            EXPLICAÇÃO DOS TERMOS<br /><br />
                            1. 1. O que é informação pessoal?<br />
                            Informação pessoal inclui dados que podem ser razoavelmente usados para identificar um indivíduo, tal como nome, endereço, telefone, e-mail, e informações de cartão de crédito. Não se trata de informação fornecida pela Portal FutebolCard durante uma transação on-line relacionada à empresa, comércio ou profissão de um indivíduo. O Portal FutebolCard também se compromete a manter seguras as informações confidenciais que receber de seus clientes comerciais. Para maiores informações sobre estas práticas de segurança, veja as declarações disponíveis quando as informações comerciais são solicitadas.<br /><br />
                            2. 2. Quem são os afiliados comerciais do Portal FutebolCard?<br />
                            Empresas que ajudam o Portal FutebolCard a preencher ou fazer o follow-up de seu pedido. O Portal FutebolCard poderá compartilhar suas informações pessoais voluntariamente fornecidas, conforme necessário, com empresas que possuem contrato com o Portal FutebolCard para preencher e/ou despachar um pedido, um prêmio, ou reembolsar, realizar pesquisas, receber pagamentos, facilitar assinaturas de e-mails, ou fornecer um produto ou serviço que você tiver solicitado. Exigimos que essas empresas utilizem as informações compartilhadas somente para esses fins limitados, e não transfiram as informações para nenhum terceiro.<br /><br />
                            Parceiros de Marcas Compartilhadas do Portal FutebolCard<br /><br />
                            Alguns produtos, serviços e promoções do Portal FutebolCard são oferecidos em conjunto com o Portal FutebolCard e com terceiros. O website para o serviço do FutebolCard será visivelmente marcado com o nome ou logotipo da outra empresa ("parceiro de marca conjunta"). O Portal FutebolCard poderá compartilhar as informações pessoais voluntariamente fornecidas, com o parceiro de marca conjunta conforme necessário para preencher suas solicitações de bens e serviços e, a menos que você nos diga para não fazê-lo, de modo que o parceiro da marca compartilhada possa enviar-lhe informações sobre produtos e serviços que possam ser de seu interesse. O parceiro de marca compartilhada também poderá coletar suas informações pessoais em separado, conforme suas próprias práticas e políticas de privacidade. O Portal FutebolCard não pode controlar e não se responsabiliza pelas práticas de privacidade de tais parceiros de marcas compartilhadas. Incentivamos você a se familiarizar com a política de privacidade do parceiro de marca compartilhada relevante antes de revelar suas informações pessoais a ele.<br /><br />
                            Revendedores Autorizados do Portal FutebolCard<br /><br />
                            Nos casos em que o Portal FutebolCard não for vender um produto diretamente, o Portal FutebolCard lhe informará a respeito disso no momento oportuno e enviará ao revendedor autorizado, distribuidor do Portal FutebolCard informações pessoais que você forneceu, necessárias ao envio dos produtos ou serviços que você adquiriu. . O uso de tais informações pelo revendedor autorizado, distribuidor do Portal FutebolCard é regido pela política própria destes, não pela política do Portal FutebolCard. O Portal FutebolCard também poderá fornecer um link para o site de um revendedor autorizado a partir de um site do Portal FutebolCard.<br /><br />
                            3. 3. Como o portal FutebolCard protege minhas informações?<br />
                            O Portal FutebolCard implanta uma grande variedade de medidas de segurança administrativas, gerenciais e técnicas para manter as informações de segurança de suas informações pessoais. As informações pessoais estão contidas em redes seguras e somente são acessadas por um número limitado de funcionários que têm acesso a tais sistemas. O uso e retenção de informações pessoais são controlados pelas Normas de Controle Interno do Portal FutebolCard. O Portal FutebolCard faz auditoria de suas organizações para assegurar conformidade com suas Normas de Controle Interno.
                        </Tab>
                        <Tab eventKey="profile" title="Contrato de Assinatura">
                            <strong>Contrato de Assinatura – FutebolCard.TV </strong>

                            <br /><br />

                            Atualizado em: 3 de novembro de 2020 <br />

                            FutebolCard Sistemas Ltda. (“FutebolCard.TV”, “nós”, “nosso/a”, “a gente”), com sede em Av. Francisco Matarazzo, 1752 - Conjuntos 516 / 517 / 518 / 519 - Perdizes - São Paulo - SP - CEP: 05001-200, Brasil e 01.329.666/0001-50 lhe dá as boas-vindas ao Serviço FutebolCard.TV.  <br /><br />

                            O “Serviço FutebolCard.TV” é um serviço personalizado de assinatura, que inclui o site FutebolCard.TV e o aplicativo, bem como os conteúdos e serviços associados.  <br /><br />

                            ESTE CONTRATO REGULARÁ O USO DO SERVIÇO FUTEBOLCARD.TV. POR FAVOR, LEIA ESTE CONTRATO DE ASSINATURA (“CONTRATO DE ASSINATURA” OU “CONTRATO”) COM CUIDADO ANTES DE USAR O SERVIÇO FUTEBOLCARD.TV.  <br /><br />

                            AO CLICAR EM “CONCORDAR E CONTINUAR” DURANTE O PROCESSO DE REGISTRO AO SERVIÇO FUTEBOLCARD.TV OU QUALQUER OUTRO MECANISMO RELACIONADO A ESSE TIPO DE SERVIÇO, VOCÊ CONCORDA PLENAMENTE E SEM RESERVAS COM ESTE CONTRATO DE ASSINATURA FUTEBOLCARD.TV E RECONHECE QUE VOCÊ LEU E ENTENDEU A NOSSA POLÍTICA DE PRIVACIDADE, CONFIRMANDO A CONCORDÂNCIA AO DESFRUTAR DE QUALQUER ELEMENTO DO SERVIÇO FUTEBOLCARD.TV. <br /><br /> 

                            CASO VOCÊ NÃO CONCORDE COM ESTE CONTRATO DE ASSINATURA FUTEBOLCARD.TV, VOCÊ NÃO PODERÁ FAZER USO DO SERVIÇO FUTEBOLCARD.TV.  <br /><br />

                            Este Contrato pode ser aditado por nós no futuro a nosso exclusivo critério. Qualquer aditamento que afete seus direitos ou obrigações passará a surtir efeito no prazo de 30 (trinta) dias após o envio de notificação nossa para o e-mail indicado por você no ato da sua inscrição . Caso você não concorde com as alterações feitas da forma acima ao Contrato de Assinatura, você poderá cancelar sua assinatura do Serviço FutebolCard.TV conforme os termos e condições especificadas na cláusula 2 abaixo.  <br /><br />

                            Ademais, certos elementos do Serviço FutebolCard.TV poderão ser alterados a qualquer momento e sem notificação prévia, por exemplo relacionados a evoluções técnicas, contanto que não haja incremento ao preço e não haja alteração de qualidade ou modificação substancial às funcionalidades do Serviço FutebolCard.TV.  <br /><br />

                            Nossos representantes de atendimento ao cliente não estão autorizados a modificar qualquer aspecto dos termos e condições do Contrato de Assinatura e dos documentos a ele relacionados, seja verbalmente ou por escrito.  <br /><br />



                            <strong>Lista de Termos e Condições:  </strong><br />

                            1. Condições de Admissibilidade e Registro do Usuário  <br />

                            2. Termos para Assinatura  <br />

                            3. Concessões e Restrições da Licença de Direitos Autorais e Copyrights  <br />

                            4. Termos de Uso <br /> 

                            5. Uso e Divulgação de Suas Informações  <br />

                            6. Suspensão e Rescisão  <br />

                            7. Outras Disposições  <br /><br />



                            <strong>1. CONDIÇÕES DE ADMISSIBILIDADE E REGISTRO DO USUÁRIO </strong> <br /><br />

                            a. Admissibilidade e Limitações por Idade.  <br />

                            Apenas residentes nos países em que oferecemos o Serviço FutebolCard.TV (coletivamente, o “Território”) são elegíveis para o registro como usuários de uma conta FutebolCard.TV. Este Contrato de Assinatura se aplica aos residentes do Brasil.  <br />

                            Você precisa ser maior de idade para adquirir o Serviço FutebolCard.TV. Menores de idade e civilmente incapazes para celebrar contratos, empresas, associações e outras entidades não podem se registrar para a obtenção de uma conta FutebolCard.TV ou usar o serviço FutebolCard.TV e não são permitidos a fornecer informações pessoais à FutebolCard.TV.  <br />

                            Como assinante, você será responsável pelos atos de todos sob seus cuidados enquanto faz/fazem uso do Serviço FutebolCard.TV. Ao acessar e utilizar o Serviço FutebolCard.TV, você concorda e confirma que é maior de idade e possui capacidade civil para se registrar e assinar o Serviço FutebolCard.TV.  <br />

                            O Serviço FutebolCard.TV é oferecido apenas pessoas físicas para uso pessoal e não-comercial.  <br /><br />

                            b. Registro e Segurança.  <br />

                            Somente pessoas físicas que se registrem para uma conta FutebolCard.TV, forneçam informações específicas (por ex.: e-mail válido) e que concordem com o presente Contrato de Assinatura estão aptos a usar o Serviço FutebolCard.TV.  <br />

                            A FutebolCard.TV adotará medidas técnicas e organizacionais para garantir a segurança de seus sistemas e processos. Você é exclusivamente responsável por manter a confidencialidade e segurança de seu nome de usuário e senha, bem como por todas as atividades que venham a ocorrer em sua conta FutebolCard.TV. No entanto, caso você conceda acesso à sua conta FutebolCard.TV para terceiros, este Contrato se aplicará ao acesso deles, uso e divulgação de informação por eles.  <br />

                            Você concorda em notificar a FutebolCard.TV imediatamente sobre qualquer acesso não autorizado à sua conta FutebolCard.TV. A FutebolCard.TV não se responsabilizará por quaisquer perdas e danos originadas do uso não autorizado da sua conta FutebolCard.TV. <br /><br /> 

                            c. Avisos.  <br />

                            Quaisquer avisos feitos pela FutebolCard.TV a você poderão ser feitos das seguintes formas:  <br />

                            (i) via e-mail ao último endereço de e-mail indicado por você ou (ii) por meio de uma postagem no Serviço FutebolCard.TV.  <br />

                            Você concorda em oferecer e manter informações cadastrais atualizadas, completas e precisas, incluindo informações de contato para o envio de avisos e outras comunicações nossas.  <br />

                            Você concorda em não usar identidade falsa ou declarar de forma imprecisa sua inscrição como sendo qualquer outra pessoa ou entidade com quem possua relação, incluindo o uso do nome de usuário, senha ou qualquer outra informação da conta, bem como do nome ou a imagem de outro, ou indicar detalhes ou dados falsos de um parente ou guardião, tutor ou curador.  <br />

                            Você concorda que a FutebolCard.TV tenha a prerrogativa de adotar medidas de confirmação da acuidade das informações fornecidas. <br /> <br />

                            d. Acesso à Internet.  <br />

                            Para a acessar o Serviço FutebolCard.TV, você precisará de acesso à Internet compatível com tráfego de streaming de vídeos (incluindo vídeos de alta resolução) arcando com os custos de seu provedor de acesso e conexão, inclusive quanto a cobranças e tributos. Favor verificar informações sobre tais custos diretamente com seu provedor de Internet.  <br />

                            e. Reconhecimento de Outros Termos.  <br />

                            Você garante que, ao aceitar os termos e condições do Contrato de Assinatura de FutebolCard.TV, você leu e entendeu a Política de Privacidade aplicável.  <br /><br />



                            <strong>2. TERMOS DA ASSINATURA  </strong><br /><br />

                            a. Assinatura e Renovação Automática.  <br />

                            Sua assinatura ao Serviço FutebolCard.TV inclui a inscrição em um plano de pagamento contínuo/recorrente. Sua assinatura será automaticamente renovada ao término do período de cobrança vigente, a não ser que tenha sido cancelado de acordo com as instruções de cancelamento abaixo, sem penalidades ou cobranças adicionais pela rescisão antecipada.  <br />

                            A cobrança será realizada pelo método de pagamento selecionado por você quando da confirmação da aquisição e com o começo de cada novo período de cobrança exceto quando cancelado tempestivamente.<br />  

                            Nós nos reservamos no direito de alterar os nossos preços. No evento de qualquer alteração de preços, nós iremos notificar você com pelo menos 30 (trinta) dias de antecedência por meio de um e-mail ao endereço de e-mail que você registrou para sua conta. Caso você não deseje aceitar a mudança de preço do Serviço, você poderá cancelar sua conta de acordo com as instruções no referido e-mail e adicionalmente aquelas fornecidas aqui abaixo e pelo e-mail, sendo que o cancelamento surtirá efeito com o término do período vigente de cobrança ou de Avaliação Gratuita, caso cancelado dentro de tal período.  <br />

                            Se você não cancelar a assinatura após a mudança de preços e antes do início do novo período de cobrança, sua assinatura será renovada de acordo com o preço em vigor à época da renovação, sem qualquer intervenção adicional sua, e você autoriza a FutebolCard.TV a realizar a cobrança desses valores pelo método de pagamento indicado por você.  <br /><br />

                            b. Avaliações Gratuitas.  <br />

                            Seu Serviço FutebolCard.TV poderá ser iniciado com um período de Avaliação Gratuita. A disponibilidade da Avaliação Gratuita não é garantida e, caso uma esteja disponível, estará disponível apenas para os assinantes que não tenham a utilizado anteriormente no Serviço FutebolCard.TV.  <br />

                            Você concorda e autoriza que seu primeiro pagamento seja cobrado do seu método de pagamento imediatamente após o término do período de Avaliação Gratuita, exceto quando cancelado de acordo com as instruções de cancelamento abaixo. Você pode cancelar sua assinatura a qualquer momento antes do término da Avaliação Gratuita.  <br />

                            Os termos da Avaliação Gratuita são apresentados no momento de registro e você não receberá notificação de que a Avaliação Gratuita está por acabar ou se encerrou, bem como de que a assinatura paga tenha se iniciado.  <br />

                            c. Alteração da Assinatura, Cancelamento, Política de Reembolso e Direito de Arrependimento.  <br />

                            Você pode alterar suas informações pessoais, bem como acessar tais dados, a qualquer momento.  <br />

                            Você poderá cancelar sua assinatura a qualquer momento antes do término do período de cobrança ativo ou da Avaliação Gratuita.  <br />

                            Você poderá ter o direito legal de arrependimento a começar do dia de aceitação deste Contrato a depender das leis aplicáveis de seu país, podendo exercê-lo por meio do formulário de rescisão anexado ao Anexo 1 ou qualquer outra declaração por escrito que expresse claramente sua intenção de cancelar a assinatura, a ser enviada pelo e-mail que pode ser localizado no endereço https://help.futebolcard.tv ou ao endereço postal indicado na cláusula 7.h abaixo.  <br />

                            Aparte disso, você terá o direito contratual de cancelamento que vigorará a partir do término do período de cobrança atual ou da Avaliação Gratuita.  <br />

                            Para cancelar sua assinatura FutebolCard.TV, acesse e navegue no www.futebolcard.tv/account/cancel-subscription e clique em “Cancelar Assinatura”. Caso você tenha se cadastrado ao Serviço FutebolCard.TV via terceiros (ex.: loja de aplicativos), favor visitar o Centro de Ajuda para instruções em como cancelar a assinatura.  <br />

                            O cancelamento somente se efetivará com o término do período de cobrança vigente (ou com o término da Avaliação Gratuita, caso você cancele o Serviço FutebolCard.TV durante tal período), e você continuará podendo acessar o Serviço FutebolCard.TV até então.  <br />

                            Não fornecemos reembolsos ou créditos por períodos utilizados parcialmente por períodos de cobrança mensal ou anual.  <br />

                            O Serviço FutebolCard.TV também poderá ser cancelado por nós, caso você faça uso dele em violação a quaisquer condições deste Contrato e das políticas ou documentos relacionados.  <br /><br />

                            d. Cancelamento e Política de Reembolso em Caso de Alterações ao Contrato.  <br />

                            No evento de uma alteração posterior a esse Contrato materialmente afetar seus direitos e obrigações, diferentemente dos casos de alteração do preço conforme regulado pela cláusula 2.a. ou de evoluções técnicas como indicado no preâmbulo, nós notificaremos você com no mínimo trinta (30) dias de antecedência à alteração por meio de um e-mail ao endereço indicado por você no ato de registro de sua conta.  <br />

                            Caso você não aceite a alteração, poderá cancelar sua assinatura em concordância com as instruções indicadas no e-mail e abaixo. Caso você não concorde com quaisquer de tais alterações a este Contrato, deverá descontinuar o uso do Serviço FutebolCard.TV e, consequentemente, caso você não cancele sua assinatura antes do início da vigência da alteração, você aceita a alteração.  <br />

                            A partir da data de cancelamento, você não poderá acessar o Serviço FutebolCard.TV e nós lhe iremos providenciar um reembolso proporcional ao período de tempo entre a data de cancelamento e o término do período de cobrança. Nesse caso, caso você tenha pago um valor promocional pela assinatura anual, o valor a ser reembolsado será calculado com base em tal desconto.  <br />

                            Para solicitar esse reembolso, envie um e-mail para nós ao endereço constante do aviso fornecido a você indicando brevemente as razões para tal pedido.  <br /><br />

                            e. Detalhes de Pagamento.  <br />

                            A FutebolCard.TV manterá arquivados os seus dados de pagamento, como o número do seu cartão de crédito e data de vencimento. <br /> 

                            Ao se tornar assinante do Serviço FutebolCard.TV e se vincular a esse Contrato, você concorda que seus dados bancários possam ser compartilhados dentro da organização FutebolCard Sistemas https://www.futebolcard.com/institutional/terms-of-use, caso você concorde com tal compartilhamento durante o processo de contratação.  <br />

                            Você é responsável por manter seus dados de pagamento atualizados por meio das configurações de conta. Quando seus dados de pagamento forem alterados ou estão por expirar, nós poderemos obter ou receber os detalhes de pagamento atualizados do seu meio de pagamento, incluindo o número do cartão de crédito, data de vencimento ou CVV (ou equivalente). Isso permite que possamos continuar a fornecer o Serviço FutebolCard.TV a você.  <br />

                            Você nos autoriza a continuar a cobrança do seu cartão de credito a partir dos dados de cobrança atualizados. Caso o pagamento não seja autorizado com sucesso devido a vencimento de dados, saldo insuficiente ou qualquer outra razão, nós poderemos suspender ou encerrar sua assinatura.  <br />

                            Você também concorda que nós podemos cobrar o seu meio de pagamento que tenhamos em arquivo caso você decida reativar a sua assinatura do Serviço FutebolCard.TV. Você pode revogar o seu consentimento para nos usarmos ou compartilharmos suas informações de pagamento a qualquer momento. Para mais detalhes veja a Política de Privacidade aplicável.  <br /><br />

                            f. Assinaturas Obtidas Por Meio De Terceiros Autorizados.  <br />

                            Caso você obtenha sua assinatura ao Serviço FutebolCard.TV por meio de terceiros autorizados (ex.: uma loja de aplicativos), sua assinatura também estará sujeita aos termos do terceiro, e as disposições do presente Contrato de Assinatura do FutebolCard.TV referentes à compra da assinatura, cobrança, cancelamento/reembolsos e pagamento não se aplicam na medida em que este Contrato de Assinatura do FutebolCard.TV entrar em conflito com os termos aplicáveis da contratação com o terceiro autorizado.  <br />

                            Para assinaturas obtidas por meio de terceiros, sua relação de cobrança será estabelecida diretamente com o terceiro aplicável. Qualquer cobrança relativa à assinatura do Serviço FutebolCard.TV será feita pelo terceiro aplicável, usando as informações de pagamentos que você tenha fornecido àquele terceiro autorizado.  <br />

                            Para cancelar a assinatura do Serviço FutebolCard.TV obtido via terceiro, favor seguir as instruções fornecidas pelo terceiro aplicável. Você pode acessar nossa Central de Ajuda para obter instruções sobre o cancelamento do Serviço FutebolCard.TV contratado via terceiros.  <br /><br />

                            g. Opções de Assinatura em Pacotes.  <br />

                            Podemos oferecer a assinatura ao Serviço FutebolCard.TV em conjunto com outros serviços de assinatura, incluindo aqueles relacionados a produtos e serviços de terceiros autorizados. As assinaturas a produtos/serviços de terceiros são reguladas pelos termos de uso e políticas emitidas por aqueles terceiros.  <br /><br />



                            <strong>3. CONCESSÕES E RESTRIÇÕES DA LICENÇA DE DIREITOS AUTORAIS  </strong><br /><br />

                            a. Licença.  <br />

                            No Território e sujeito aos termos e condições deste Contrato de Assinatura, a FutebolCard.TV lhe garantirá o direito limitado, de uso pessoal, intransferível, não passível de cessão, revogável, não exclusivo e não sub licenciável para:  <br />

                            i. instalar e fazer uso pessoal, não comercial, do Serviço FutebolCard.TV; <br />  

                            ii. Receber por streaming ou temporariamente baixar material protegido por direitos autorais, copyrights, marcas registradas, nomes de domínio ou demais leis de propriedade intelectual ou industrial, incluindo, mas não limitado a filmes, programas de televisão, outros programas de entretenimento ou informação, trailers, materiais-bônus, imagens e ilustrações (o “Conteúdo FutebolCard.TV”) que são disponibilizados por meio do Serviço FutebolCard.TV.  <br />

                            Este Contrato lhe concede uma licença nos termos supramencionados e não deve ser interpretado como um acordo para venda ou cessão de quaisquer direitos do Conteúdo FutebolCard.TV ou do Serviço FutebolCard.TV.  <br />

                            A aquisição de uma licença para realizar o streaming ou baixar temporariamente qualquer Conteúdo FutebolCard.TV não se traduz nenhum direito de propriedade sobre tal Conteúdo FutebolCard.TV ou no sobre o Serviço FutebolCard.TV.  <br />

                            Referidos Conteúdo FutebolCard.TV e Serviço FutebolCard.TV, incluindo os direitos autorais, copyrights, marcas registradas, marcas de serviço, nomes comerciais, imagens comerciais e outros direitos de propriedade intelectual inerentes ao Conteúdo FutebolCard.TV e aplicáveis no Território ou em outros lugares, são de propriedade da FutebolCard Sistemas, suas afiliadas e/ou outros licenciantes, e são protegidos por leis de direito autoral, copyright marca registrada, modelos de utilidade, propriedade industrial, nomes de domínio e/ou outras leis de propriedade intelectual e tratados aplicáveis. <br /><br /> 

                            b. Restrições ao seu uso do Conteúdo FutebolCard.TV.  <br />

                            Você concorda que, como condição de sua licença, não deverá e concorda em não:  <br />

                            i. burlar ou desativar qualquer sistema de proteção do conteúdo ou tecnologia de controle dos direitos digitais que sejam utilizados em conjunto no Serviço FutebolCard.TV para controlar acesso ao Conteúdo FutebolCard.TV;  <br />

                            ii. copiar, replicar ou fixar o Conteúdo FutebolCard.TV (exceto quando permitido de forma expressa por este Contrato de Assinatura);<br />  

                            iii. retransmitir, transmitir ou apresentar publicamente o Conteúdo FutebolCard.TV disponibilizado pelo Serviço FutebolCard.TV, por qualquer meio ou processo;  <br />

                            iv. criar obras audiovisuais baseadas no Conteúdo FutebolCard.TV; ou <br /> 

                            v. permitir que terceiros violem qualquer das restrições acima.  <br /><br />



                            c. Restrições ao seu uso do Serviço FutebolCard.TV.  <br />

                            Você concorda que, como condição de sua licença, não deverá e concorda em não:  <br />

                            i. mover, descompilar, praticar engenharia reversa, desmontar, converter ou reduzir o Serviço FutebolCard.TV e/ou o(s) reprodutores(es) de mídia, tecnologia de base, tecnologia de controle dos direitos digitais ou qualquer outra medida protetiva de acesso ao conteúdo que os reprodutores de mídia porventura possuam, a uma forma humanamente inteligível;  <br />

                            ii. modificar o Serviço FutebolCard.TV, incluindo, mas não limitado à remoção de identificação de direitos autorais, copyrights ou quaisquer outros avisos de propriedade sobre o Conteúdo FutebolCard.TV ou o Serviço FutebolCard.TV. <br /> 

                            iii. acessar ou usar o Serviço FutebolCard.TV de forma que sugere qualquer associação com os produtos, serviços ou marcas da FutebolCard.TV;  <br />

                            iv. usar o Serviço FutebolCard.TV para qualquer uso comercial ou empresarial, bem como em estabelecimentos comerciais, áreas públicas (ex.: lobbies, bares, restaurantes, estádios, cassinos, clubes, cafés, teatros etc.) ou com a intenção de realizar negócios com base no Conteúdo FutebolCard.TV ou Serviço FutebolCard.TV, visando o lucro ou não;  <br />

                            v. criar obras audiovisuais derivadas de quaisquer componentes do Serviço FutebolCard.TV de propriedade da FutebolCard Sistemas, atualizações ou qualquer parte de tal;  <br />

                            vi. burlar, modificar, manipular ou contornar qualquer função ou proteção do Serviço FutebolCard.TV;  <br />

                            vii. acessar, monitorar ou copiar, ou permitir que qualquer pessoa ou entidade acesse, monitore ou copie qualquer elemento do Serviço FutebolCard.TV por meio de um robô, indexador automático ou qualquer outro meio automatizado/manual, sem que haja permissão expressa da FutebolCard.TV para tal;  <br />

                            viii. danificar, desabilitar, sobrecarregar ou prejudicar o Serviço FutebolCard.TV; <br /> 

                            ix. usar o Serviço FutebolCard.TV de forma ilícita, para qualquer finalidade ilegal que seja, ou de maneira inconsistente com este Contrato de Assinatura do FutebolCard.TV;  <br />

                            x. compartilhar suas credenciais de acesso, incluindo, sem limitação, nome de usuário e senha com terceiros;  <br />

                            xi. fornecer informações pessoais imprecisas, falsas ou incompletas. <br /> 

                            xii. violar ou tentar violar a segurança do Serviço FutebolCard.TV, incluindo, mas não se limitando a: avaliar ou testar a vulnerabilidade do sistema ou rede; burlar as medidas de segurança ou identificação; impedir o acesso ao Serviço FutebolCard.TV a outros usuários, hospedeiros ou redes de qualquer forma; falsificar pacotes TCP/IP ou qualquer parte das informações de um endereço de e-mail; ou  <br />

                            xiii. permitir que terceiros violem as restrições acima. d. Violações. Qualquer tentativa de praticar quaisquer das restrições listadas nas seções 3b e 3c caracteriza violação aos direitos da FutebolCard.TV e dos detentores de direitos autorais e copyrights, podendo resultar em responsabilização criminal e/ou civil.  <br /><br />



                            <strong>4. TERMOS DE USO DO SERVIÇO FUTEBOLCARD.TV  </strong><br /><br />

                            a. FutebolCard.TV Dispositivos Compatíveis.  <br />

                            O uso do Serviço FutebolCard.TV requer um Dispositivo compatível, e certos softwares demandam atualizações periódicas e o uso do Serviço FutebolCard.TV por você pode ser afetado pelo desempenho desses dispositivos, softwares e outros elementos fora do nosso controle.  <br />

                            Você poderá acessar o Conteúdo FutebolCard.TV por meio de quase todo computador com uma conexão à Internet ou através do aplicativo do Serviço FutebolCard.TV disponível para certos Dispositivos móveis e outros dispositivos (conexão à Internet é necessária em todo caso) (individualmente, “Dispositivo Compatível”).  <br />

                            Para questões específicas envolvendo Dispositivos, sistemas operacionais e navegadores compatíveis, bem como relativo ao suporte de transmissão ideal, favor visite nossa Central de Ajuda. Você pode adicionar um Dispositivo Compatível ao seu Serviço FutebolCard.TV ao baixar o aplicativo do Serviço FutebolCard.TV no Dispositivo Compatível e registrando-o na sua conta do Serviço FutebolCard.TV pelo aplicativo.  <br /><br />

                            b. Conexão à Internet.  <br />

                            Você precisará de uma conexão à Internet de alta velocidade/banda larga para acessar e usar determinadas funcionalidades do Serviço FutebolCard.TV. A qualidade de seu acesso ao Conteúdo FutebolCard.TV e Serviço FutebolCard.TV pode ser afetada por aspectos relacionados à sua conexão à Internet ou à velocidade, largura de banda disponíveis no momento e local de acesso, bem como sua localização quando acessando através de dispositivos móveis.  <br /><br />

                            c. Streaming do Conteúdo FutebolCard.TV.  <br />

                            O Conteúdo FutebolCard.TV pode ser transmitido através do Serviço FutebolCard.TV por meio de uma conexão à Internet. Até 4 (quatro) transmissão simultâneas são permitidas na sua conta do Serviço FutebolCard.TV a qualquer momento. O número de transmissões por streaming simultâneas disponíveis pode variar, sem prejuízo aos seus direitos de cancelamento e reembolso conforme descritos na cláusula 2.d.  <br /><br />



                            d. Baixar Conteúdo FutebolCard.TV.  <br />

                            O Conteúdo FutebolCard.TV pode ser disponibilizado para download temporário, de forma a possibilitar a visualização offline (sem conexão à Internet) em determinados Dispositivos Compatíveis, apenas nas formas e períodos aqui indicados. Os downloads são limitados a um total de 10 (dez) Dispositivos Compatíveis. <br /> 

                            Você não está permitido a baixar em um Dispositivo Compatível adicional a partir de quando este limite é atingido. Você deverá conectar seu Dispositivo Compatível à Internet e acessar o Serviço FutebolCard.TV a cada 30 (trinta) dias para que o Conteúdo FutebolCard.TV baixado permaneça disponível para visualização offline (sem Internet). Esse período de tempo no qual os downloads temporários ficam disponíveis pode ser alterado.  <br /><br />

                            e. Perfil do Assinante.  <br />

                            A FutebolCard.TV oferece a possibilidade de personalizar o uso do Serviço FutebolCard.TV por meio da criação de um ou mais perfis sob uma mesma conta. Você poderá designar um perfil como conta Infantil, de forma a restringir o acesso a certos Conteúdos FutebolCard.TV por meio de tal perfil. Favor visitar a Central de Ajuda para maiores informações relacionadas aos perfis Infantis.  <br />

                            O uso do Serviço FutebolCard.TV por menores deve ocorrer sob supervisão de adultos e a FutebolCard.TV não deve ser responsabilizada por esse tipo de uso. <br /><br />

                            f. Disponibilidade do Conteúdo FutebolCard.TV.<br />  

                            Como regra geral, o Serviço FutebolCard.TV será disponibilizado somente no Território. No entanto, certos Conteúdos FutebolCard.TV disponíveis por meio da assinatura ao Serviço FutebolCard.TV poderão não ser acessáveis em todos os países ou regiões dentro do Território.  <br />

                            Restrições geográficas estarão vigentes de acordo com a localização na qual você está assinando o Serviço FutebolCard.TV, e a FutebolCard.TV poderá usar quaisquer tecnologias e métodos para verificar sua localização geográfica.  <br /><br />

                            g. Indisponibilidade Futura.  <br />

                            É possível que o Serviço FutebolCard.TV e/ou parte do Conteúdo FutebolCard.TV não seja acessível para transmissão online (streaming) ou download a qualquer momento, incluindo:<br />  

                            (i) durante manutenções do sistema ou períodos de atualização;  <br />

                            (ii) perda de energia em servidores; <br /> 

                            (iii) por resultado de conflito armado, guerras, rebeliões/motins, greves ou crises sociais, epidemias, pandemias, quarentenas; ou  <br />

                            (iv) em razão de atos de governos ou suas agências, seja em caso de emergência ou demais eventos imprevisíveis, de força maior ou fato fortuito que estejam fora do nosso controle ou de terceiros.  <br />

                            A FutebolCard.TV tomará os esforços razoáveis para oferecer notificação prévia sobre tais interrupções, sempre que não forem publicamente disponíveis; existe a possibilidade de certas funcionalidades, dispositivos ou plataformas serem removidas do acesso à FutebolCard.TV.  <br />

                            Nós faremos o nosso melhor para cientificá-lo de tais alterações, restrições e regras de uso, mas você pelo presente reconhece e concorda que poderão ser feitas a critério exclusivo nosso sem notificação prévia.  <br />

                            Você concorda que a FutebolCard.TV não será responsabilizada, exceto em casos de flagrante negligência ou má conduta de nossa parte por qualquer modificação, suspensão ou descontinuação do Serviço FutebolCard.TV, todavia, caso você seja um assinante e precisemos suspender ou descontinuar sua assinatura ao Serviço FutebolCard.TV, possuiremos a discricionariedade de oferecer um crédito, reembolso, desconto ou qualquer outra forma de bonificação a nosso exclusivo critério. Porém, caso seu acesso ao Serviço FutebolCard.TV seja suspenso ou encerrado por força de violações ao presente Contrato de Assinatura do FutebolCard.TV pelo usuário você não será elegível a qualquer crédito, reembolso, desconto ou outra compensação.  <br /><br />

                            h. Recursos Experimentais e Promocionais.  <br />

                            Na nossa constante avaliação do Serviço FutebolCard.TV, a nós poderemos de tempos em tempos e concernente a qualquer um ou todos os usuários, experimentar ou oferecer certos recursos ou elementos do Serviço FutebolCard.TV, incluindo promoções, interfaces, planos e preços.  <br />

                            Seu uso de qualquer versão atualizada, modificada ou substituída do Serviço FutebolCard.TV será regulado pelo presente Contrato de Assinatura de FutebolCard.TV e quaisquer termos adicionais com os quais você concordar no ato de instalação de tais versões atualizadas, modificadas ou substituídas.  <br /><br />



                            <strong>5. USO E DIVULGAÇÃO DE SUAS INFORMAÇÕES  </strong><br /><br />

                            Quando você solicita uma conta do Serviço FutebolCard.TV, lhe serão solicitadas determinadas informações pessoais, incluindo, mas sem limitação dependendo de seu país de residência, o seu nome completo e endereço válido de e-mails. <br /> 

                            Você reconhece que a FutebolCard Sistemas (Argentina) S.A. é a controladora de dados responsável pelo processamento de seus dados pessoais, de forma a oferecê-lo o Serviço FutebolCard.TV. Para fins deste Contrato de Assinatura e na medida do determinado pela legislação aplicável a FutebolCard Sistemas Brasil Ltda, poderá ter também considerada um controlador de dados conjuntamente responsável por processar seus dados pessoais.  <br />

                            Para maiores informações referentes à coleta, uso e compartilhamento de suas informações, favor verificar a POLÍTICA DE PRIVACIDADE da FutebolCard Sistemas e os termos de privacidade especificamente aplicáveis ao Brasil Ambos os documentos estão disponíveis na área legal do FutebolCard.TV. A FutebolCard.TV utilizará seus dados para personalizar e melhorar sua experiência com o Serviço FutebolCard.TV, bem como para lhe enviar informações sobre o Serviço FutebolCard.TV.  <br />

                            Você pode alterar suas preferências de comunicação a qualquer momento. Poderemos utilizar seus dados conforme descrito na Política de Privacidade e/ou nos termos de privacidade específicos aplicáveis ao Brasil, inclusive compartilhá-los entre o grupo FutebolCard Sistemas.  <br />

                            Você reconhece que a FutebolCard.TV poderá acessar, preservar ou divulgar informações que você nos fornece quando entender, em boa fé, que esse acesso, preservação ou divulgação é necessária para:  <br />

                            (i) cumprir um contrato ou tratativas preliminares relativas a um contrato do qual você seja uma parte;  <br />

                            (ii) proteger ou defender os direitos legais ou a propriedade da FutebolCard.TV, de nossas afiliadas ou subsidiárias, seus empregados, agentes e contratados (incluindo para impor as cláusulas de contratos) nos Tribunais, procedimentos arbitrais ou administrativos; <br /> 

                            (iii) proteger a segurança de usuários do Serviço FutebolCard.TV ou membros do público, inclusive os que estejam atuando em circunstâncias urgentes; (iv) proteger contra fraude ou para fins de controle de riscos;  <br />

                            (v) cumprir com obrigações legais ou regulatórias; ou  <br />

                            (vi) proteger os legítimos interesses nossos ou nossas terceiras partes.  <br /><br />



                            <strong>6. SUSPENSÃO E RESCISÃO  </strong><br /><br />

                            a. Ao aceitar esse Contrato e criar sua conta FutebolCard.TV, você concorda que a FutebolCard.TV possa, a seu próprio critério e sem notificação ou responsabilidade perante você, restringir, suspender ou rescindir seu acesso a parte ou o Serviço/Conteúdo FutebolCard.TV como um todo, caso haja suspeição de que você esteja utilizando o Serviço FutebolCard.TV em descumprimento com o Contrato de Assinatura de FutebolCard.TV ou com qualquer lei ou regulamentação aplicável Assim ocorrido, lhe notificaremos em antecedência por meio de um e-mail ao endereço indicado no ato de registro da conta. Neste caso, você não terá direito a compensação ou reembolso de qualquer natureza.  <br />

                            Sem limitação ao supracitado, a FutebolCard.TV poderá restringir ou suspender seu acesso à sua conta FutebolCard.TV com justa causa e mediante notificação a você, que poderá ser comunicada de forma eletrônica, entendendo-se por “justa causa” o seguinte, mas não limitado a tal:  <br />

                            (i) requerimento de autoridades policiais ou governamentais;  <br />

                            (ii) problemas técnicos inesperados; ou  <br />

                            (iii) caso a FutebolCard.TV suspeite razoavelmente que sua conta do Serviço FutebolCard.TV tenha sido criada ou acessada de forma fraudulenta, ou até que tenha sido utilizada por qualquer pessoa para cometer fraude ou outra finalidade que não aquela prevista, em acordo com qualquer diretriz ou requisito aplicável.  <br />

                            A FutebolCard.TV se reserva o direito, após notificação endereçada a você, a rescindir qualquer conta do Serviço FutebolCard.TV que permaneça inativa por um ano ou mais (a falta de acesso à sua conta do Serviço FutebolCard.TV constitui inatividade para os propósitos deste Contrato de Assinatura do Serviço FutebolCard.TV).  <br />

                            b. Ao aceitar esse Contrato e criar sua conta FutebolCard.TV, você concorda com que a FutebolCard.TV não seja considerada responsável em relação a você ou qualquer terceiro por qualquer restrição, suspensão ou rescisão de seu acesso à conta do Serviço FutebolCard.TV ou ao Conteúdo FutebolCard.TV, a não ser em casos de flagrante negligência ou má conduta intencional. <br /> 

                            c. Após o cancelamento da sua conta do Serviço FutebolCard.TV, seja cancelada pela FutebolCard.TV ou a seu pedido (exceto um cancelamento da sua assinatura que permita que a sua assinatura continue até o final do período de cobrança), você perderá imediatamente o direito de acessar o Conteúdo FutebolCard.TV transmitido pelo serviço FutebolCard.TV e, dentro de um período limitado, perderá o direito de visualizar o Conteúdo FutebolCard.TV baixado por meio do Serviço FutebolCard.TV .  <br /><br />



                            <strong>7. OUTRAS DISPOSIÇÕES </strong><br /><br />

                            a. Ao aceitar esse Contrato e criar sua conta FutebolCard.TV, você compreende e concorda que o Conteúdo FutebolCard.TV recebido através do Serviço FutebolCard.TV possui finalidade apenas informativa e de entretenimento. <br />

                            O Conteúdo FutebolCard.TV não constitui aconselhamento ou diagnóstico legal, financeiro, profissional, médico ou clínico, não podendo ser utilizado para tais propósitos.  <br /><br />

                            b. Subjetividade do Conteúdo FutebolCard.TV.  <br />

                            O Conteúdo FutebolCard.TV tende a suscitar reações variáveis entre pessoas diferentes. Você poderá encontrar Conteúdos FutebolCard.TV e considerá-los ofensivos, indecentes, explícitos ou condenáveis. Também classificações etárias, tipos, gêneros, categorias e/ou descrições são fornecidas para auxiliar o usuário na navegação, a título informativo.  <br />

                            Nós não garantimos que você concordará com tais informações nem deverá ser responsabilizada pelas reações ou opiniões que nossos usuários ou seus grupos familiares demonstrarem pelo conteúdo. Você reconhece tais riscos e sua responsabilidade por fazer suas próprias escolhas em relação ao Conteúdo FutebolCard.TV apropriado para você e sua família.  <br />

                            Você reconhece e concorda que o Serviço FutebolCard.TV possui funcionalidades e recursos que permitem:  <br />

                            (i) a criação de perfis para menores de idade; e  <br />

                            (ii) a prevenção de acesso a determinados Conteúdos FutebolCard.TV; Você declara e reconhece que você exerce controle sobre o acesso adequado aos Conteúdos FutebolCard.TV por menores de idade, isentando a FutebolCard.TV de qualquer responsabilidade sobre suas ações e omissões, assim como as de menores de idade sob seus cuidados. <br /><br /> 

                            c. Foto sensibilidade.  <br />

                            O Conteúdo FutebolCard.TV poderá conter imagens piscantes, sequências de flashes ou padrões visuais que possam afetar usuários suscetíveis a epilepsia fotossensível ou outras condições similares. Adicionalmente, Conteúdos FutebolCard.TV em 4K Ultra HD HDR permitem maior brilho e saturação de cor, o que pode afetar o usuário.  <br /><br />



                            d. Qualidade do Conteúdo FutebolCard.TV.  <br />

                            Nós utilizamos diversas tecnologias para proporcionar uma experiência audiovisual ideal. Por exemplo, qualidades de imagem HD e 4K Ultra HD estão disponíveis para determinados Conteúdos FutebolCard.TV. Dito isso, a qualidade de reprodução do Conteúdo FutebolCard.TV, incluindo resolução, pode ser afetada pelo formato do Conteúdo FutebolCard.TV, sua localização, a velocidade, largura de banda e termos específicos de seu serviço de Internet, o Dispositivo Compatível utilizado ou outros fatores.  <br />

                            O tempo para que se inicie a visualização do Conteúdo FutebolCard.TV pode variar de acordo com uma série de fatores, incluindo sua localização, largura de banda da Internet, número de Dispositivos conectados simultaneamente à mesma rede, o próprio Conteúdo FutebolCard.TV selecionado, bem como a configuração do Dispositivo Compatível utilizado. Por consequência, a FutebolCard.TV não dá quaisquer garantias quanto ao Conteúdo FutebolCard.TV a esses respeitos.  <br /><br />

                            e. Serviços de Terceiros e Conteúdos FutebolCard.TV.<br />  

                            O Serviço FutebolCard.TV pode integrar, ser integrado a ou ser oferecido em conjunto com serviços e conteúdo de terceiros. Nós não controlamos tais serviços e conteúdos e, portanto, não é responsável por assuntos relativos a tais serviços e conteúdo.  <br />

                            Quando você acessa tais serviços e conteúdo de terceiros, o faz a seu próprio risco, portanto precisará ler os termos de uso, contratos e políticas de privacidade aplicáveis ao uso de tais serviços e conteúdo de terceiros.  <br />

                            Caso você acesse o Serviço FutebolCard.TV por meio de serviços ou Dispositivos de terceiros, por exemplo, Dispositivo Apple iOS, Android ou Microsoft Windows, então tal companhia terceira que ofereça os serviços ou Dispositivos em questão será terceira beneficiária do presente Contrato de Assinatura. No entanto, estes terceiros beneficiários não se configuram como partes deste Contrato de Assinatura.  <br />

                            Você reconhece e concorda que seu acesso ao Serviço FutebolCard.TV por meio de tais Dispositivos estará sujeito aos termos de uso dos respectivos beneficiários e de seus termos de serviço.  <br /><br />

                            f. Redes Móveis. <br />

                            Quando você acessa o Serviço FutebolCard.TV através de uma rede remota, as respectivas taxas e valores da rede, roaming e dados se aplicarão conforme estabelecidas pelos provedores do serviço.  <br />

                            Baixar, instalar ou usar o Serviço FutebolCard.TV poderá ser proibido ou restringido por seu provedor de Internet móvel ou o Serviço FutebolCard.TV poderá não funcionar de acordo com o seu provedor de Internet móvel ou Dispositivo. Verifique, por favor, a compatibilidade do seu serviço de rede móvel ou do seu dispositivo com o FutebolCard.TV Service diretamente com seus fornecedores. <br /><br /> 

                            g. Política de Contribuições e Ideias não Solicitadas.  <br />

                            Nossa política interna não nos permite aceitar ou considerar ideias criativas, sugestões ou materiais não solicitados. Em relação a qualquer coisa que você porventura deseje apresentar, tenha sido solicitado ou não, você concorda que tais ideias criativas, sugestões ou materiais apresentados não são regidos por confidencialidade e que nenhuma relação fiduciária é pretendida ou criada entre você e a nós e que não deve antecipar qualquer expectativa de que ser vista, compensação ou receba um pagamento de qualquer tipo.  <br />

                            A FutebolCard.TV não pleiteia propriedade sobre quaisquer ideias, sugestões ou materiais apresentados; no entanto, em relação a tais materiais, você garante à FutebolCard.TV uma licença global não exclusiva, sub licenciável, irrevogável e livre de pagamento a título de royalties sobre todo direito autoral, marca registrada, patente, segredo de negócio, direito de privacidade ou publicidade ou outros direitos de propriedade intelectual para usar, reproduzir, transmitir, apresentar, criar obras derivadas ou explorar para qualquer propósito sem limite temporal, forma e frequência de uso, sem notificação a você, com ou sem atribuição e sem necessidade de autorização sua ou pagamento a você ou qualquer outra pessoa/entidade.  <br /><br />

                            h. Informações de contato.<br />  

                            Você pode contatar a FutebolCard.TV das seguintes formas: por telefone ou pelo e-mail que pode ser localizado no endereço https://help.futebolcard.tv/ ou via postal para FutebolCard Sistemas (Brasil) Ltda., World Trade Center, Av. Das Nações Unidas, 12.551, 12.555, 12.559, Piso 10, São Paulo/SP – CEP 04578-903, Brasil.  <br /><br />

                            i. Isenções de Garantia; Limitação de Responsabilidade.  <br />

                            Você reconhece e concorda com a condição essencial de que o Conteúdo FutebolCard.TV e o Serviço FutebolCard.TV sejam fornecidos "no estado em que se encontram" e sem garantias de qualquer tipo. Na extensão máxima permitida pela lei aplicável, exceto nos casos de negligência grave ou má conduta dolosa, a FutebolCard.TV , suas afiliadas, licenciantes, agentes e prestadores de serviços (coletivamente, as "Partes FutebolCard.TV ") negam expressamente quaisquer garantias e condições, expressas, implícita ou estatutária, incluindo, mas não se limitando a, garantias ou condições de comercialização, adequação a uma finalidade específica, precisão, completude, disponibilidade, segurança, compatibilidade, qualidade satisfatória e não violação e seus equivalentes sob as leis de qualquer jurisdição .  <br />

                            Você também reconhece e concorda que as Partes FutebolCard.TV não garantem que o Conteúdo FutebolCard.TV e o Serviço FutebolCard.TV não ocorram erros, vírus ou outros componentes nocivos. Na extensão máxima permitida pela lei aplicável, exceto nos casos de negligência grave ou má conduta dolosa, as Partes FutebolCard.TV não serão responsáveis por nenhum dano indireto, especial, incidental ou consequente de qualquer tipo, incluindo lucros cessantes e danos à propriedade, mesmo que fomos avisados da possibilidade de tais danos, resultantes do uso ou da incapacidade de usar o Conteúdo da FutebolCard.TV ou o Serviço FutebolCard.TV, independentemente de sua causa. A menos que seja causado por negligência grave ou conduta dolosa, não seremos responsabilizados por atraso ou falha no desempenho resultante de causas fora do nosso controle razoável.  <br />

                            Em nenhum caso, nossa responsabilidade perante você por todo e qualquer dano, perda ou causa de ação excederá o valor pago por você. As limitações de responsabilidade nesta seção aplicam-se sob qualquer teoria legal (ilícito civil, contrato, quebra de garantia, responsabilidade estrita ou outra) e mesmo que as Partes FutebolCard.TV tenham sido avisadas da possibilidade de tais danos. <br /><br /> 



                            j. Foro.  <br />

                            Qualquer litígio entre você e a FutebolCard.TV ou suas afiliadas deverá ser dirimido no Foro Central da Cidade e Estado de São Paulo.  
                            <br /><br />
                            k. Resolução Alternativa do Conflitos.  <br />

                            Você também poderá optar por mecanismos extrajudiciais para solucionar litígios relacionados a estes termos, por meio das entidades competentes conforme descrito na legislação consumerista aplicável.  <br /><br />

                            l. Legislação Aplicável.  <br />

                            Este Contrato de Assinatura FutebolCard.TV e qualquer disputa ou reivindicação que dele surjam ou a ele estejam relacionados são regulados e interpretados de acordo com o Direito e com as leis consumeristas aplicáveis.  <br /><br />

                            m. Responsabilidade.  <br />

                            Quando da criação da conta do Serviço FutebolCard.TV e acesso ao Serviço FutebolCard.TV, você reconhece e concorda que tal uso do Serviço FutebolCard.TV e acesso ao Conteúdo FutebolCard.TV ocorrem sob sua integral e exclusiva responsabilidade.  <br /><br />

                            n. Termos do Serviço FutebolCard.TV.  <br />

                            Você compreende e aceita que a FutebolCard.TV possa cancelar ou suspender o Serviço FutebolCard.TV ou Conteúdo FutebolCard.TV parcialmente ou integralmente, a qualquer momento e com isenção de responsabilidade, exceto quanto aos direitos de reembolso ou compensação estritamente definidos neste Contrato.  <br />

                            o. Cessão de Direitos.  <br /><br />

                            A FutebolCard.TV poderá ceder ou transferir, parcialmente ou integralmente, seus direitos relacionados a este Contrato de Assinatura a qualquer companhia do seu grupo econômico, assim entendido qualquer empresa com a qual possua relação empresarial de coligação, controle comum ou com sócios/acionistas em comum.  <br />

                            A cessão ou transferência também é permitida a quaisquer terceiros que sucedam aos ativos ou direitos da FutebolCard.TV em relação à base de assinantes do Serviço FutebolCard.TV, assim como em casos de fusão, incorporação ou aquisição. Cessões ou transferências podem ser realizadas sem notificação a você.  <br />

                            Você aceita que não deverá e confirma que não cederá ou transferirá seus direitos ou obrigações deste este Contrato. <br /><br /> 

                            p. Uso Autorizado.  <br />

                            Você pelo presente aceita e se compromete a utilizar o Serviço FutebolCard.TV diligentemente em concordância com a legislação aplicável, com este Contrato de Assinatura e com os bons costumes.  <br />

                            Você aceita não utilizar o Serviço FutebolCard.TV ou o Conteúdo FutebolCard.TV de qualquer forma contrária à lei aplicável ou que de qualquer forma cause danos ou impeça o uso normal do Serviço FutebolCard.TV por terceiros ou usuários, bem como não utilizado para fins comerciais ou visando ao lucro.  <br /><br />



                            q. Linguagem Prevalente. <br /> 

                            O Contrato de Assinatura FutebolCard.TV poderá também ser disponibilizado a você nas línguas Inglesa e Espanhola . Você reconhece e concorda que, na hipótese de discrepância entre a versão em Inglês, em Espanhol e em Português a versão em português prevalecerá.<br /><br />  

                            r. Exclusão de Cláusula.  <br />

                            Caso alguma disposição deste Contrato de Assinatura de FutebolCard.TV seja ilícita, inválida ou inexequível por qualquer razão, será considerada como excluída destes termos e não afetarão a validade e aplicabilidade das demais disposições. <br /> 

                            Você e a FutebolCard.TV deverão substituir a parte ilícita, inválida ou inexequível por disposições que sejam válidas e vinculantes, cujos efeitos à luz do conteúdo e objetivo do Contrato de Assinatura sejam, à melhor medida possível, similares aos da parte ilícita, inválida ou inexequível.  <br /><br />

                            s. Superveniência.  <br />

                            Quaisquer disposições deste Contrato de Assinatura de FutebolCard.TV que, por natureza, permanecem vigentes mesmo com a rescisão do presente Contrato de Assinatura de FutebolCard.TV, seguirão desta forma surtindo efeitos.  <br /><br />

                            t. Contrato Integral. <br />

                            Este Contrato de Assinatura para assinante FutebolCard.TV e suas disposições constituem a contratação entre você e FutebolCard.TV em relação à matéria nele discutida, sobrepondo-se a eventuais acordos, entendimentos, negociações e tratativas, verbais ou por escrito.<br />  

                            Nenhuma renúncia a qualquer disposição deste Contrato de Assinatura do Serviço FutebolCard.TV deve ser vista de forma a constituir renúncia a qualquer outra previsão contratual (similar ou não), bem como não constituirá uma renúncia contínua a não ser quando expressamente indicado como tal.  <br /><br /><br />

























                            ANEXO 1 – Modelo de Formulário de Cancelamento  <br /><br />

                            (Caso você deseje exercer seu direito de arrependimento/cancelamento em relação a este Contrato de Assinatura, favor preencher esse formulário e enviá-lo de volta via e-mail ou a nosso endereço postal)  <br /><br />

                            Endereço postal: FutebolCard Sistemas Brasil Ltda. World Trade Center, Av. Das Nações Unidas, 12.551, 12.555, 12.559, Piso 10, São Paulo/SP – CEP 04578-903, Brasil  <br /><br />

                            E-mail: Para o e-mail encontrado no endereço eletrônico: <a href="https://help.futebolcard.tv/" target="_blank">https://help.futebolcard.tv/</a> <br /><br />



                            Eu/nós (*), por meio da presente, revogo(amos) o contrato celebrado por mim/nós (*) para a assinatura ao Serviço FutebolCard.TV.  

                            <br /><br />

                            Data de assinatura:<br />  

                            Nome do(s) Cliente(s):  <br />

                            Endereço postal do(s) Cliente(s):  <br />

                            Endereço de e-mail utilizado para criar a conta FutebolCard.TV:  <br />



                            Assinatura do(s) Cliente(s) (apenas quando enviado em via física)<br />  <br /> 



                            __________________                                                                            
                            <br /> 
                            (*) Deletar conforme necessário 
                        </Tab>
                    </Tabs>
                </Container>
            </div>
        )
    }
}

export default LegalTerms