import React from 'react';
import  { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer  from './components/Footer/Footer';
import CookiesModal from './components/CookiesModal/CookiesModal';

import Home from './pages/Home';
import Login from './pages/Login';
import Error from './pages/Error';
import Register from './pages/Register';
import Payment from './pages/Payment';
import Video from './pages/Video';
import Landing from './pages/Landing';
import Contract from './pages/Contract';
import Contact from './pages/Contact';
import LegalTerms from './pages/LegalTerms';
import Forgot from './pages/password/forgot';
import Recover from './pages/password/recover';
import MyList from './pages/MyList';
import Account from './pages/Account';
import ChangePassword from './pages/ChangePassword';
import Search from './pages/Search';
import Pack from './pages/Pack';
import PackList from './pages/PackList';
import UpgradePack from './pages/UpgradePack';
import Reactivate from './pages/Reactivate';
import ChangePayment from './pages/ChangePayment';

import user from './functions/user';
import purchasedPack from './functions/purchasedPack';

// const Restrict = (info) => {
//     purchasedPack.getPurchasedPack().then(response => {
//         console.log('Restrict - ' + response.data.data.status);
//         let info = repsonse.data.data.status;
        
//         return info;
//     }).catch(error => {
//         console.log(error);
//         return 'canceled'
//     });
// }


const isCanceled = {
    canceled: false,
    canceledStatus: '',
    authenticate() {
        purchasedPack.getPurchasedPack().then(response => {
            this.canceledStatus = response.data.data.status
            this.canceled = true

            console.log(this.canceled)
            console.log(this.canceledStatus)
        }).catch(error => {
        });
    }
}

const restrict = () => isCanceled.authenticate(() => {
    return isCanceled.canceledStatus;
})

restrict()

const PrivateRoute = ({component: Component, ...rest})  => (
    <Route {...rest} render = { props => 
        user.hasToken() ? 
            isCanceled.canceledStatus == 'canceled' ? 
                (<Redirect to={{pathname: '/reactivate'}} />) :
                (<Component {...props}/>) 
            : (<Redirect to={{pathname: '/'}} />)
    } />
);

const Routes = () => {
    return (
        <BrowserRouter>
            <Header isLogged={localStorage.getItem('token') ? (true) : (false)} isReady={localStorage.getItem('name') ? (true) : (false)} />
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute path="/home" component={Home} />
                    <PrivateRoute path="/video/:id" component={Video} />
                    <PrivateRoute path="/contract" component={Contract} />
                    <PrivateRoute path="/payment" component={Payment} />
                    <PrivateRoute path="/account" component={Account} />
                    <PrivateRoute path="/change-password" component={ChangePassword} />
                    <PrivateRoute path="/my-list" component={MyList} />
                    <PrivateRoute path="/pack/:id" component={Pack} />
                    <PrivateRoute path="/packlist" component={PackList} />
                    <PrivateRoute path="/upgrade-pack" component={UpgradePack} />
                    <PrivateRoute path="/search" component={Search} />
                    <Route path="/reactivate" component={Reactivate} />
                    <Route path="/change-payment" component={ChangePayment} />
                    <Route path="/forgot" component={Forgot} />
                    <Route path="/recover/:hash" component={Recover} />
                    <Route path="/register" component={Register} />
                    <Route path="/legal-terms" component={LegalTerms} />
                    <Route path="/contact" component={Contact} />
                    <Route path="*" component={Error} />
                </Switch>
            {localStorage.getItem('showCookies') ? '' : <CookiesModal />} 
            <Footer />
        </BrowserRouter>
    );
}

export default Routes;