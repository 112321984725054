
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './CreditCardForm.scss';
import InputMask from "react-input-mask";
import ApiFcardTv from '../../HttpRequest/ApiFcardTv';
import ApiSsoFcard from '../../HttpRequest/ApiFcardSso';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import TransitionsModal from '../../ErrorModal/ErrorModal'
import Loading from '../../Loading/Loading';
import paymentFormValidator from '../../../functions/paymentFormValidator';
import User from '../../../functions/user';
import { TransferWithinAStation } from '@material-ui/icons';

class CreditCardForm extends Component {
        constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            transactionType: '',
            cardNumber: '',
            cardHolder: '',
            expMonth: '',
            expYear: '',
            securityCode: '',
            cpf: '',
            modal: false,
            error: '',
            validated: {
                'status': false,
            }
        };
        
        this.handleChange           = this.handleChange.bind(this);
        this.changeCPF              = this.changeCPF.bind(this);
        this.changeSecurityCode     = this.changeSecurityCode.bind(this);
        this.changeCardNumber       = this.changeCardNumber.bind(this);
        this.changeExpirationDate   = this.changeExpirationDate.bind(this);
        this.handlePayment          = this.handlePayment.bind(this);
        this.validateForm           = this.validateForm.bind(this);
        this.changeTransactionType  = this.changeTransactionType.bind(this); 
    }

    changeCPF(event) {
        this.setState({ cpf: event.target.value, modal: false });
    }

    changeSecurityCode(event) {
        let securityCode = event.target.value;
        let securityCodeStr = securityCode.replace('_','');
        console.log(securityCodeStr);
        
        this.setState({ securityCode: securityCodeStr, modal: false });
    }

    changeCardNumber(event) {
        this.setState({ cardNumber: event.target.value, modal: false });
    }

    changeExpirationDate(event) {
        let expirationDate = event.target.value;
        let arrExpirationDate = expirationDate.split('/');

        this.setState({ 
            expMonth: arrExpirationDate[0], 
            expYear: arrExpirationDate[1]
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value, modal: false});
    }

    changeTransactionType(event) {
        this.setState({
            transactionType: event.target.value,
            modal: false
        })
    }

    handlePayment()
    {      
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        ApiFcardTv.post('payment' , {
            "transactionType":"credit",
            "cart": localStorage.getItem('cart'),
            "installments":"1",
            "cardNumber": this.state.cardNumber,
            "cardHolder": this.state.cardHolder,
            "expMonth": this.state.expMonth,
            "expYear": this.state.expYear,
            "securityCode": this.state.securityCode,
            "email": localStorage.getItem('email')
        }).then(response => {
            if (this.state.transactionType === 'debit') {
                alert('ainda não está funcionando, tente crédito');
            } else {
                User.insertPersonData(localStorage.getItem('user'), this.state.cardHolder, this.state.cpf, localStorage.getItem('document_type')).then(responsePerson => {
                    this.setState({isLoading: false});
                    localStorage.setItem('payment-success', true);
                    localStorage.setItem('name', responsePerson.data.data[0].first_name);
                    this.props.history.push("/home");
                    window.location.reload();
                });
            }

        }).catch(error => {
            console.log(error.response);
            this.setState({isLoading: false});
            this.setState({modal: true, error: error.response.data.data.message});
        });
    }

    async validateCpf(validated) {
        if (!validated.status) {
            return false;
        }
        
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        await ApiSsoFcard.get('person/cpf/validate/' + this.state.cpf)
        .then(response => {
            if (!response.data.data.isValid) {
                validated.status = false;
                validated.message = 'Número de cpf inválido';

                this.setState({validated: validated});
            } else {
                validated.status = true;

                this.setState({validated: validated});
            }   
        });

    }

    async validateForm(e) {
        e.preventDefault();
        this.setState({isLoading: true});


        let validated = paymentFormValidator.validate(
            true,
            this.state.transactionType,
            this.state.cardNumber,
            this.state.securityCode,
            this.state.cardHolder,
            this.state.cpf,
            this.state.expMonth,
            this.state.expYear,
        );
       
        this.setState({validated: validated})

        await this.validateCpf(validated);          

        if (!this.state.validated.status) {
            this.setState({isLoading: false});
            this.setState({modal: true, error: this.state.validated.message});
            return;
        }

        this.handlePayment();
    }

    render() {
        return(
            <form className="credit-card-form mt-4" onSubmit={this.validateForm}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <label>Tipo de cartão:</label>
                        <div className="gray-box d-flex justify-content-center align-items-center">
                            <div className="mr-2">
                                <input type="radio" checked="checked" name="paymentTypes" id="radio1" value="credit" onChange={this.changeTransactionType}/> <label for="radio1" className="m-0">Crédito</label>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <label class="w-100">Número do cartão:</label>
                        <InputMask mask="9999 9999 9999 9999" autoComplete="cc-csc" maskPlaceholder={null} className="form-control"  onChange={this.changeCardNumber} value={this.state.cardNumber} />
                    </Grid>
                    <Grid item xs={6}>
                        <label class="w-100">Data de validade:</label>
                        <InputMask mask="99/99" maskPlaceholder={null} autoComplete="cc-csc" className="form-control"  onChange={this.changeExpirationDate} value={this.state.expirationDate} />
                    </Grid>
                    <Grid item xs={6}>
                        <label class="w-100">Código de segurança:</label>
                        <div className="form-wrap">
                            <span class="info-label" data-tip="O código de segurança de 3 ou 4 digitos fica na parte de trás do cartão."><InfoIcon /></span>
                            <InputMask mask="9999" maskPlaceholder={null} autoComplete="cc-csc" className="form-control"  onChange={this.changeSecurityCode} value={this.state.securityCode} />
                        </div>
                        <ReactTooltip />
                    </Grid>
                    <Grid item xs={12}>
                        <label class="w-100">Titular do cartão:</label>
                        <input type="text" class="form-control" autoComplete="cc-csc" name="cardHolder" onChange={this.handleChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <label class="w-100">CPF:</label>
                        <div className="form-wrap">
                            <span class="info-label" data-tip="O CPF deve ser o titular do cartão."><InfoIcon /></span>
                            <InputMask mask="999.999.999-99" maskPlaceholder={null} autoComplete="cc-csc" className="form-control" onChange={this.changeCPF} value={this.state.cpf} />
                        </div   >
                    </Grid>
                    {this.state.isLoading ? (<Loading />) : (<></>)}

                    <Grid item xs={12}>
                        <input type="submit" className="form-submit" onClick={() => this.setState({modal: false})} value="Concordar e assinar" />
                    </Grid>

                </Grid>
                
                {this.state.modal ? (<TransitionsModal>{this.state.error}</TransitionsModal>) : (<div></div>)}
                

            </form>
        );
    }
}
export default withRouter(CreditCardForm);