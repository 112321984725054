import ApiFcardTv from '../components/HttpRequest/ApiFcardTv';

class videos {

    searchVideos(value, tagName) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/videos', {
            params: {
                string: value,
                pack: tagName
            }
        });
    }

}

export default new videos();