import React, { Component } from 'react';
import BannerCarousel from '../../components/BannerCarousel/BannerCarousel';
import CategoryCaorousel from '../../components/CategoryCarousel/CategoryCarousel';
import user  from '../../functions/user';
import purchasedPack from '../../functions/purchasedPack';
import pack from '../../functions/pack';
import Loading from '../../components/Loading/Loading';
import WelcomeModal from '../../components/WelcomeModal/WelcomeModal';
import './home.css';

class Home extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            playLists: [],
            cardsList: [],
            order: 1,
            atBottom: false,
            paymentSuccess: false,
        }

        this.handleScroll = this.handleScroll.bind(this);

        window.addEventListener('scroll', this.handleScroll, true);

    }

    async validatePruchasedPack() {
        return await purchasedPack.purchasedPackValidate();
    }

    async validateToken() {
        return await user.tokenValidate();
    }

    async getPackPlayLists(packId) {
        return await pack.getPlayListsValidated(packId);
    }

    async componentDidMount() {
        this.setState({ isLoading: false });

        if (localStorage.getItem('payment-success') === 'true') {
            this.setState({ paymentSuccess: true });
        }

        Promise.all([this.validatePruchasedPack(), this.validateToken()])
        .then(responses => {
            if (responses[0].data.data.status) {
                this.getPackPlayLists(responses[0].data.data.pack.id).then(response => {
                    this.setState({
                        playLists: response.data.data,
                    }); 

                    console.log('response.data.data = ' + response.data.data);
                    console.log('this.state.playLists[0].playlist = ' + this.state.playLists[0].playlist);
   
                    this.setState({
                        cardsList: [<CategoryCaorousel playList={this.state.playLists[0].playlist} purchased={this.state.playLists[0].is_purchased}/>, <CategoryCaorousel playList={this.state.playLists[1].playlist} purchased={this.state.playLists[1].is_purchased}/>]
                    });
                    
                }).catch(error => {

                });   
                
                this.setState({ isLoading: false });
            } else {
                //redirecionar para uma página
                this.props.history.replace('/');
            }

        }).catch(errors => {
            if (errors.response.status === 404) {
                this.props.history.replace('/contract');
            } else {
                localStorage.removeItem('token');
                this.props.history.replace('/login');
            }
        })
    }
        
    isBottom(element) {
        return element.getBoundingClientRect().bottom <= window.innerHeight;
    }

    handleScroll(event) {
        let position = this.state.order + 1;
        const element = document.getElementById('home-box');

        if (!this.state.atBottom && this.isBottom(element)) {

            this.setState({atBottom: true});

            if (position < this.state.playLists.length) {
                this.setState({
                    order: position
                });

                console.log('handleScroll - position playlist result: ' + this.state.playLists[position].playlist);
    
                //let heightBound = window.innerHeight * 0.2;
                //alert(window.innerHeight);
                //if (heightBound < window.scrollY) {
                    const cardsList = this.state.cardsList;
                    this.setState({
                        cardsList: cardsList.concat(<CategoryCaorousel playList={this.state.playLists[position].playlist} purchased={this.state.playLists[position].is_purchased} />)
                    });
               // } 
            }

        }

        if (!this.isBottom(element)) {
            this.setState({atBottom: false});
        }
    }

    render() {
        return(
            <div id="home-box" className="home-box">
                {this.state.isLoading ? 
                    (
                        <Loading size='3x' inverse='inverse' />
                    ) : (
                        <>
                            {this.state.paymentSuccess ? 
                                (
                                    <WelcomeModal />
                                ) : (
                                    <></>
                                )
                            }
                     
                            <BannerCarousel />
                            {this.state.cardsList.map(card => {
                                return card;
                            })}
            
                        </>
                    )
                }



            </div>
        );
    }
}

export default Home;