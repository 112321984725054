import React, { Component} from 'react';
import { withRouter, Link } from "react-router-dom";
import './VideoList.scss';
import user  from '../../functions/user';
import Loading from '../Loading/Loading';
import Delete from '../../pages/MyList/delete.png';

class VideosList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false
        };

        this.deleteMyList  = this.deleteMyList.bind(this);
    }

    async deleteMyList(video) {
        await user.deleteMyPlayList(video).then(response => {;
            window.location.reload();
            return false;
        }).catch(error => {
            console.log(error.response.data);
        });
    }

    formatText(text) {
        let str = text.split('-');
        let response;
        str.map(value => {
            response = <>{response}{value}<br /></> 
        });

        return response;
    }

    render(){
        const videos = this.props.list;

        return(
            this.state.isLoading ? 
                (
                    <Loading size="2x" inverse="inverse"/>
                ) : (
                    <div className="VideosList">
                        {
                            videos.map(video =>
                                <div className="video">

                                        <Link to={"/video/" + video.video_id} >
                                            <img src={video.thumbnail} className="img-fluid" />
                                        </Link>
                                        <p>{this.formatText(video.title)}</p>

                                        {this.props.buttons === 'true' ? 
                                            (
                                                <p className="VideosList-remove" onClick={() => this.deleteMyList(video.video_id)}><img src={Delete} height="14" /> Remover da lista</p>
                                            ) : ( 
                                                <></>
                                            )
                                        }
                                </div>
                            )
                        }
                    </div>
                )
            
        )
    }
}

export default withRouter(VideosList)