
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import toastr from 'toastr';
import purchasedPack from '../../functions/purchasedPack';
import Loading from '../Loading/Loading';

class CancellationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalTitle: 'Cartão vermelho!',
            modalOpen: false,
            isLoading: false,
            isError: false,
            errorMessage: null,
        }

        this.handleClose  = this.handleClose.bind(this);
        this.cancelPack = this.cancelPack.bind(this);
    }

    cancelPack(){
        
        /* API */
        this.setState({isLoading: true});
        purchasedPack.cancelPurchasedPack().then(response => {

            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 10000
            }
            toastr.clear();
            setTimeout(() => toastr.success(`Sentiremos a sua falta.<br />
                                            O cancelamento foi realizado com sucesso.<br />
                                            Volte quando quiser.`), 300);

            this.setState({isLoading: false});
            this.props.history.push('/home');

        }).catch(error => {


            this.setState({
                isLoading: false,
                errorMessage: error.response.data.data.message
            });

            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 6000
            }
            toastr.clear()
            setTimeout(() => toastr.error(`${this.state.errorMessage}`), 300);

            this.props.history.push('/account');
            
        });
    }
    
    handleClose(e) {
        
        this.props.history.push('/home');
        this.setState({
            modalOpen: false,
        });
    }

    render(){
        return(
            <div>
                <button className="light-button" type="button" onClick={() => this.setState({modalOpen: true})}>Cancelar plano</button>

                {this.state.isLoading ? 
                    (
                        <Loading />
                    ) : (
                        <></>
                    )
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="error-modal"
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div className="modal-content">
                            <h2 id="transition-modal-title">{this.state.modalTitle}</h2>
                            <p id="transition-modal-description">
                                Não quer mais participar do jogo?<br />
                                Sentiremos sua falta, mas se tiver certeza que quer cancelar sua assinatura, confirme abaixo.
                                <br /><br />
                                Volte ao jogo quando quiser.
                            </p>
                            <button className="register-form-submit" onClick={this.cancelPack}>Confirmo o cancelamento</button>
                            <button className="light-button" onClick={this.handleClose}>Voltar</button>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }
}

export default withRouter(CancellationModal);