import React, {Component} from 'react';
import RegisterForm from '../../components/RegisterForm/RegisterForm';

class Register extends Component {
    render() {
        return(
            <div className="register-page">
                <RegisterForm />
            </div>
        );
    }
}

export default Register;