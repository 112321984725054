import React, { Component } from 'react';
import './CookiesModal.scss'

class CookiesModal extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        const acceptCookies = () => {
            document.querySelector(".box-cookies").classList.add('hideEffect');
            localStorage.setItem("showCookies", "accept");
        };
            
        const btnCookies = document.querySelector(".btn-cookies");
        btnCookies.addEventListener('click', acceptCookies);

    }

    render(){


        return(
            <div>
                <div class="box-cookies">
                    <p class="msg-cookies">Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços, personalizar publicidade e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento. Informamos ainda que atualizamos nossa Política de Privacidade.</p>
                    <button class="btn-cookies">Concordar</button>
                </div>
            </div>
        )
    }
}

export default CookiesModal;