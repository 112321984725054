import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './LoadingInner.scss';

const LoadingInner = (props) => {
    return (
        <div className="loading-frame">
            {props.inverse === 'inverse' ?
            (<FontAwesomeIcon icon={faSpinner} spin inverse size="2x" />) :
            (<FontAwesomeIcon icon={faSpinner} spin size="2x" />)
            }
        </div>
    ) 
}

export default LoadingInner;

