import React, { Component } from  'react';
import Routes from '../../routes';
import './App.css';

class App extends Component
{
  render()  {
    return( 
      <>
        <Routes />
      </>
    )
  }
}

export default App;
