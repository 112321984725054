class registerFormValidator {

    validate(login, password, repeatPassword) {
        const passValidate = this.validatePassword(password, repeatPassword); 

        if (!passValidate.status) {
            return { status: false, message: passValidate.message }
        }

        const validateLogin = this.validateLogin(login);

        if (!validateLogin.status) {
            return { status: false, message: validateLogin.message }
        }

        return { status:true }
    }

    validateLogin(email) {
        if (email === null || email === '') {
            return {
                status: false,
                message: "Digite seu e-mail."
            }
        }

        let user = email.substring(0, email.indexOf("@"));
        let domain = email.substring(email.indexOf("@")+ 1, email.length);

        if ((user.length >=1) && (domain.length >=3) && (user.search("@")==-1) &&
            (domain.search("@")==-1) && (user.search(" ")==-1) && (domain.search(" ")==-1) &&(domain.search(".")!=-1) &&
            (domain.indexOf(".") >=1)&& (domain.lastIndexOf(".") < domain.length - 1)) {
                return {status: true}
            
        } else {
            return {
                status: false,
                message: "O e-mail digitado não é válido, tente novamente."
            }
        }
    }

    validatePassword(password, repeatPassword) {
        if (password !== repeatPassword) {
            return {
                status: false,
                message: 'Os campos "Senha" e "Confirme sua senha" devem ser iguais.'
            };
        }
        
        if (password.length < 8) {
            return {
                status: false,
                message: 'A senha deve conter no mínimo 8 caracteres.'
            };
        }

        return {status: true};
    }

}

export default new registerFormValidator();