import ApiFcardTv from '../components/HttpRequest/ApiFcardTv';

class pack {

    getAllPacks() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/packs');
    }

    getPlayLists() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/pack/playlists/account/' + localStorage.getItem('account'));
    }

    getPlayListsValidated(packId) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/pack/' + packId + '/playlists/account/' + localStorage.getItem('account'));
    }

    getPackByTag(packTag) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/pack/tag/' + packTag);
    }

}

export default new pack();