import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ApiSsoFcard from './../HttpRequest/ApiFcardSso';
import Loading from '../Loading/Loading';
import { Link } from 'react-router-dom';
import recoverFormValidator from '../../functions/recoverFormValidator';


class RecoverForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: null,
            password_repeat: null,
            isSuccess: false,
            isError: false,
            isLoading: false,
            successMessage: 'Senha alterada com sucesso!',
            errMessage: null,
            passwordStrength: '',
        };

        this.auth = this.auth.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }

    auth() {
        const client = process.env.REACT_APP_CLIENT;
        const key = process.env.REACT_APP_KEY;

        return ApiSsoFcard.post('client', {
            'client': client,
            'key': key
        });
    }

    recoverPassword(token) {
        ApiSsoFcard.defaults.headers.common['Authorization'] = token;
        return ApiSsoFcard.put('user/password_recover', {
            'hash': this.props.match.params.hash,
            'password': this.state.password
        });
    }
    

    handleForm(e) {
        e.preventDefault();
        this.setState({isLoading: true});

        this.auth().then(authResponse => {
            this.sendEmail(authResponse.data.data.token).then(response => {
                this.setState({
                    isLoading: false,
                    isSuccess: true,
                });
            }).catch(error => {
                if (!error.response.data.data.exist) {
                    this.setState({
                        isLoading: false,
                        errMessage: 'O e-mail digitado não foi localizado, tente novamente.',
                        isError: true,
                    })
                }
            });
        }).catch(error => {

        });

    }

    handleForm(e) {
        e.preventDefault();

        this.setState({isLoading: true});

        const validate = recoverFormValidator.validate(this.state.user, this.state.password, this.state.password_repeat);

        if (!validate.status) {
            this.setState({
                isLoading: false,
                isError: true,
                errMessage: validate.message
            });
            
        } else {
            this.auth().then(authResponse => {
                this.recoverPassword(authResponse.data.data.token).then(response => {
                    this.setState({
                        isLoading: false,
                        isSuccess: true,
                    });
                }).catch(error => {
                    if (error.response.data.status === 'fail'){
                        if (error.response.data.data.message) {
                            this.setState({
                                isLoading: false,
                                errMessage: error.response.data.data.message,
                                isError: true,
                            })
                        } else {
                            this.setState({
                                isLoading: false,
                                errMessage: 'Não foi possível alterar a senha',
                                isError: true,
                            })
                        }
                    }
                });
            }).catch(error => {
    
            });
        }
    }

    handlePassword(event){
        this.setState({password: event.target.value});
        var score = 0
        var pass = event.target.value

        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i=0; i<pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        if (score > 80)
            return this.setState({passwordStrength: "pw-str strong"});
        if (score > 60)
            return this.setState({passwordStrength: "pw-str good"});
        if (score >= 30)
            return this.setState({passwordStrength: "pw-str weak"});
    
        return this.setState({passwordStrength: ""});;
    }

    componentDidMount() {

    }
    
    
    render() {
        return (
            <div className="login-page">
                <div className={this.state.isSuccess ? "login-form login-form-reset" : "login-form" }>
                    
                    <div className="login-box">
                    {this.state.isSuccess ? 
                        (
                            <>
                                <div class="error-modal text-center">
                                    <div class="modal-content">
                                        <h2>Goool!!!</h2>
                                        <p className="py-4">
                                            {this.state.successMessage}
                                        </p>
                                        <Link className="close-btn" to="/login">Entrar</Link>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="login-form-title">Crie nova senha</p>
                                {this.state.isLoading ? (<Loading size="2x" />) : ''}

                                <form  onSubmit={this.handleForm} id="loginForm">
                                    <input className="login-form-input" type="password" autoComplete="off" autoFocus value={this.state.password} onChange={(e) => this.handlePassword(e)} placeholder="Senha" />

                                    <input className="login-form-input" type="password" autoComplete="off" value={this.state.password_repeat} onChange={(e) => this.setState({password_repeat: e.target.value})} placeholder="Confirme a senha" />
                                    
                                    <div className={this.state.passwordStrength}></div>
                                    <div className="pw-type">
                                        <div>Baixa</div>
                                        <div>Média</div>
                                        <div>Alta</div>
                                    </div>

                                    <p className="register-texts py-3 text-center px-4">
                                        A senha deve ter no mínimo 8 caracteres contemplando ao menos uma letra minúscula, uma maiúscula e um número.
                                    </p>

                                    {this.state.isError ? (<p className=" pt-3 login-form-error-message">{this.state.errMessage}</p>) : (<></>)}

                                    <input className="login-form-submit" type="submit" value="Trocar senha"/>

                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(RecoverForm);