
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './ChangePaymentForm.scss';
import InputMask from "react-input-mask";
import ApiFcardTv from '../HttpRequest/ApiFcardTv';
import ApiSsoFcard from '../HttpRequest/ApiFcardSso';
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TransitionsModal from '../ErrorModal/ErrorModal'
import Loading from '../Loading/Loading';
import paymentFormValidator from '../../functions/paymentFormValidator';
import purchasedPack  from '../../functions/purchasedPack';
import User from '../../functions/user';
import toastr from 'toastr';
import { TransferWithinAStation } from '@material-ui/icons';
import ReplayIcon from '@material-ui/icons/Replay';
import user from '../../functions/user';

class ChangePaymentForm extends Component {
        constructor(props) {
        super(props);
        
        this.state = {
            isLoading: false,
            isActive: true,
            transactionType: '',
            cardNumber: '',
            cardHolder: '',
            expMonth: '',
            expYear: '',
            securityCode: '',
            cpf: '',
            modal: false,
            error: '',
            cardData: null,
            payPackage: false,
            purchasedPack: '',
            lastDigits: '',
            validated: {
                'status': false,
            }
        };
        
        this.handleChange           = this.handleChange.bind(this);
        this.changeCPF              = this.changeCPF.bind(this);
        this.changeSecurityCode     = this.changeSecurityCode.bind(this);
        this.changeCardNumber       = this.changeCardNumber.bind(this);
        this.changeExpirationDate   = this.changeExpirationDate.bind(this);
        this.handlePayment          = this.handlePayment.bind(this);
        this.validateForm           = this.validateForm.bind(this);
        this.changeTransactionType  = this.changeTransactionType.bind(this); 
        this.paymentSavedCreditCard = this.paymentSavedCreditCard.bind(this);
    }

    changeCPF(event) {
        this.setState({ cpf: event.target.value, modal: false });
    }

    changeSecurityCode(event) {
        let securityCode = event.target.value;
        let securityCodeStr = securityCode.replace('_','');
        console.log(securityCodeStr);
        
        this.setState({ securityCode: securityCodeStr, modal: false });
    }

    changeCardNumber(event) {

        let cardvalue = event.target.value.replace(/ /g,'');
        let cardfinal = cardvalue.toString().replace(/\d{4}(?=.)/g, '$& ');
        
        console.log(cardfinal);

        this.setState({ cardNumber: cardfinal, modal: false });

    }

    changeExpirationDate(event) {
        let expirationDate = event.target.value;
        let arrExpirationDate = expirationDate.split('/');

        this.setState({ 
            expMonth: arrExpirationDate[0], 
            expYear: arrExpirationDate[1]
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value, modal: false});
    }

    changeTransactionType(event) {
        this.setState({
            transactionType: event.target.value,
            modal: false
        })
    }

    handlePayment()
    {      
        this.setState({
            isLoading: false,
            updateSuccess: true,
            open: false
        });

        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        ApiFcardTv.post('payment/change' , {
            "accountId": localStorage.getItem('account'),
            "transactionType":"credit",
            "cart": localStorage.getItem('cart'),
            "installments":"1",
            "cardNumber": this.state.cardNumber.replace(/ /g,''),
            "cardHolder": this.state.cardHolder,
            "expMonth": this.state.expMonth,
            "expYear": this.state.expYear,
            "securityCode": this.state.securityCode,
            "is_payment": this.state.payPackage,
            "email": localStorage.getItem('email')
        }).then(response => {
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }

            toastr.clear();
            setTimeout(() => toastr.success(`Forma de pagamento alterada com sucesso.`), 300);

            this.props.history.push('/account');

        }).catch(error => {
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }

            toastr.clear();
            setTimeout(() => toastr.error(`Erro, tente novamente mais tarde.`), 300);

        });
    }

    async paymentSavedCreditCard(){

        this.setState({isLoading: true});

        await user.payWithSavedCreditCard().then(response => {
            this.setState({
                isLoading: false
            });
    
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
    
            toastr.clear();
            setTimeout(() => toastr.success(`Pagamento efetuado com sucesso.`), 300);
    
            this.props.history.push('/account');


        }).catch(error => {
            console.log(error);
            
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
    
            toastr.clear();
            setTimeout(() => toastr.error(`Erro ao tentar efetuar o pagamento, tente com outro cartão.`), 300);

            this.setState({
                isLoading: false
            });
        });

    }

    async validateCpf(validated) {
        if (!validated.status) {
            return false;
        }
        
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        await ApiSsoFcard.get('person/cpf/validate/' + this.state.cpf)
        .then(response => {
            if (!response.data.data.isValid) {
                validated.status = false;
                validated.message = 'Número de cpf inválido';

                this.setState({validated: validated});
            } else {
                validated.status = true;

                this.setState({validated: validated});
            }   
        });

    }

    async validateForm(e) {
        e.preventDefault();
        this.setState({isLoading: true});

        let validated = paymentFormValidator.validate(
            false,
            this.state.transactionType,
            this.state.cardNumber,
            this.state.securityCode,
            this.state.cardHolder,
            '12345678910',
            this.state.expMonth,
            this.state.expYear,
        );
       
        this.setState({validated: validated})

        if (!this.state.validated.status) {
            this.setState({isLoading: false});
            this.setState({modal: true, error: this.state.validated.message});
            return;
        }

        this.handlePayment();
    }

    componentDidMount() {

        User.getCardData().then(response => {
            
            let cardvalue = response.data.data[0].card_number.slice(response.data.data[0].card_number.length - 4);
            
            this.setState({
                lastDigits: cardvalue
            });

        }).catch(error => {
        });

        purchasedPack.getPurchasedPack().then(response => {
            this.setState({purchasedPack: response.data.data.status});
        }).catch(error => {

        });


    }

    render() {
        console.log('PurshasedPack: ' + this.state.purchasedPack);

        return(
            <div class="box-center change-payment">
                <step className="register-step">Para continuar</step> <br />
                <div className="register-form-title">
                    Atualize sua<br />forma de pagamento
                </div>
                
                <div className="change-payment--methods">
                    <div className="change-payment--type active">

                        <div>
                            <h3>Cartão atual</h3>
                            <small>Final {this.state.lastDigits}</small>
                        </div>

                        {this.state.purchasedPack == 'canceled' ? (
                            <div className="text-right">
                                <a onClick={this.paymentSavedCreditCard} href="#"><ReplayIcon /> Tentar novamente</a>
                            </div>
                        ) : (
                            <></>
                        )}
                        
                    </div>
                    <div className="change-payment--type">
                        <div>
                            <h3><CreditCardIcon /> Alterar cartão</h3>
                            <small>Alterar cartão cadastrado</small>
                        </div>
                    </div>
                    <form className="credit-card-form mt-1" onSubmit={this.validateForm}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <label>Tipo de cartão:</label>
                                <div className="gray-box d-flex justify-content-center align-items-center">
                                    <div className="mr-2">
                                        <input type="radio" checked="checked" name="paymentTypes" id="radio1" value="credit" onChange={this.changeTransactionType}/> <label for="radio1" className="m-0">Crédito</label>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <label class="w-100">Número do cartão:</label>
                                <input autoComplete="cc-csc" maskPlaceholder={null} className="form-control"  onChange={this.changeCardNumber} value={this.state.cardNumber} />
                            </Grid>
                            <Grid item xs={6}>
                                <label class="w-100">Data de validade:</label>
                                <InputMask mask="99/99" maskPlaceholder={null} autoComplete="cc-csc" className="form-control"  onChange={this.changeExpirationDate} value={this.state.expirationDate} />
                            </Grid>
                            <Grid item xs={6}>
                                <label class="w-100">Código de segurança:</label>
                                <div className="form-wrap">
                                    <span class="info-label" data-tip="O código de segurança de 3 ou 4 digitos fica na parte de trás do cartão."><InfoIcon /></span>
                                    <InputMask mask="9999" maskPlaceholder={null} autoComplete="cc-csc" className="form-control"  onChange={this.changeSecurityCode} value={this.state.securityCode} />
                                </div>
                                <ReactTooltip />
                            </Grid>
                            <Grid item xs={12}>
                                <label class="w-100">Titular do cartão:</label>
                                <input type="text" class="form-control" autoComplete="cc-csc" name="cardHolder" onChange={this.handleChange} value={this.state.cardHolder}/>
                            </Grid>
                            {this.state.isLoading ? (<Loading />) : (<></>)}

                            <Grid item xs={12}>
                                {this.state.isActive ? (    
                                    <input type="submit" className="form-submit" onClick={() => this.setState({modal: false})} value="Alterar" />
                                ): (
                                    <input type="submit" className="form-submit" onClick={() => this.setState({modal: false})} value="Realizar pagamento" />
                                )}
                            </Grid>

                        </Grid>
                        
                        {this.state.modal ? (<TransitionsModal>{this.state.error}</TransitionsModal>) : (<div></div>)}

                    </form>
                </div>

            </div>
        );
    }
}
export default withRouter(ChangePaymentForm);