import React, {Component} from 'react';
import Player from '../../components/Player/Player';
import user  from '../../functions/user';

class Video extends Component 
{
    async componentDidMount() {
        user.tokenValidate()
        .then(response => {
            return;
        })
        .catch(error => {
            this.props.history.replace('/login')
        })
    }

    render() {
        return(
            <div className="video-page">
                <Player />
            </div>
        );
    }
}

export default Video;