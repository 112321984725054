import React, { Component } from 'react'
import './WelcomeModal.scss'
import logo from '../../pages/Landing/logo.svg'
import CancelIcon from '@material-ui/icons/Cancel';

class WelcomeModal extends Component
{
    constructor(props){
        super(props)

        this.state = {
            visible: true
        }

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        localStorage.removeItem('payment-success');
        this.setState({ visible: false })
    }

    render(){
        return(
            <div>
                {this.state.visible ? (
                    <div className={this.state.visible ? "WelcomeModal open-modal" : "WelcomeModal close-modal"}>
                        <div className="content">
                            <p>
                                <a className="btn-close" onClick={this.handleClose}>
                                    <CancelIcon fontSize="large" /><br />
                                    Fechar
                                </a>
                                Gooool de letra!!! <br /><br />
                                Agora você pode curtir a melhor seleção <br /> de conteúdos na &nbsp;<img src={logo} height="80" /><br />
                                <br />
                                <a className="btn btn-default" onClick={this.handleClose}>Assista agora</a>
                            </p>
                        </div>
                    </div>
                ) : null }
            </div>
        )
    }
}

export default WelcomeModal