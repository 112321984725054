class loginFormValidator {

    validateEmail(email) {

        if (email === null || email === '') {
            return {
                status: false,
                message: "Digite seu e-mail."
            }
        }

        let user = email.substring(0, email.indexOf("@"));
        let domain = email.substring(email.indexOf("@")+ 1, email.length);

        if ((user.length >=1) && (domain.length >=3) && (user.search("@")==-1) &&
            (domain.search("@")==-1) && (user.search(" ")==-1) && (domain.search(" ")==-1) &&(domain.search(".")!=-1) &&
            (domain.indexOf(".") >=1)&& (domain.lastIndexOf(".") < domain.length - 1)) {
                return {status: true}
            
        } else {
            return {
                status: false,
                message: "O e-mail digitado não é válido, tente novamente."
            }
        }
    }
}

export default new loginFormValidator();