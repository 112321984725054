import React, {Component} from 'react';
import VideosList from '../../components/VideosList/VideosList';
import user  from '../../functions/user';
import Loading from '../../components/Loading/Loading';
import Add from './add.svg'

class MyList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            videoList: [],
        }


    }

    componentDidMount() {
        user.tokenValidate()
        .then(response => {
            return;
        })
        .catch(error => {
            this.props.history.replace('/login')
        })

        user.myPlayList().then(response => {
            this.setState({
                isLoading: false,
                videoList: response.data.data[0],
            }); 
        }).catch(error => {
            // this.props.history.replace('/home')
        });
    }

    render() {
        return(

            this.state.isLoading ?
            (
                <Loading size="2x" inverse="inverse"/>
            ) : (
                <div>
                {
                    this.state.videoList.length < 1 ? 
                        (
                            <div className="page-ops">
                                <img src={Add} height="100" />
                                <h3>Sua lista está vazia</h3>
                                <p>
                                    O conteúdo que você colocar na Minha Lista aparecerá aqui.
                                </p>
                            </div>
                        ) : (
                            <div className="page">
                                <h3>Minha lista</h3>
                                
                                <VideosList list={this.state.videoList} buttons="true" />
                            </div>
                        )
                }
                </div>
            )
        );
    }
}

export default MyList