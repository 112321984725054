import React, { useState, useEffect, Component } from 'react';
import './PaymentForm.scss';
import Select from 'react-dropdown-select';
import { Tabs, useTabState, Panel } from '@bumaga/tabs';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CreditCardForm from './PaymentMethods/CreditCardForm';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ApiFcardTv from '../HttpRequest/ApiFcardTv';
import Grid from '@material-ui/core/Grid';
import packs from '../../functions/pack';

const cn = (...args) => args.filter(Boolean).join(' ');

const Tab = ({ children, state }) => {
  const { isActive, onClick } = useTabState()
  
  return (
    <button disabled={state ? 'disabled' : ''} className={cn('tab', isActive && 'active')} onClick={onClick}>
      {children}
    </button>
  )
}

const PaymentTabs = () => {
    const [index, setIndex] = useState(0)
    const [enabled, setenabled] = useState(false)

    // use setIndex as you want
    // for example force to second tab in initial render
    useEffect(() => {
        setIndex(0)
        setenabled(true)
    }, [])

    return (
        <Tabs state={[index, setIndex]}>
            <div className="tabs">
                <Tab><CreditCardIcon /> Cartão</Tab>
                {/* <Tab state={[enabled, setenabled]}><ReceiptIcon /> Boleto</Tab> */}
            </div>

            <Panel>
                <CreditCardForm />
            </Panel>

            {/* <Panel>
                BoletoForm
            </Panel> */}
        </Tabs>
    )
}


class PaymentForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            cardNumber: '',
            cardName: '',
            expirationDate: '',
            cvv: '',
            plans: [],
            selectedPlan: {
                status: false
            },
 
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.setSelectedPlan =  this.setSelectedPlan.bind(this);
        this.addDays = this.addDays.bind(this);
    }

    async componentDidMount()
    {
        let plans = [];
        let formattedPlans;
        packs.getAllPacks().then(response => {
            response.data.data.map(packs => {
                formattedPlans = {
                    'name': packs.name,
                    'label': packs.name +' '+ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(packs.value),
                    'value': packs.id,
                    'amount': packs.value
                };

                plans.push(formattedPlans);
            })

            this.setState({
                plans: plans,
               // selectedPlan: {label: plans[0].label, value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(plans[0].amount)}
             });

         }).catch(error => {
             console.log(error);
         });
     }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value});
    }

    setSelectedPlan(value)
    {

        document.querySelector('.react-dropdown-select').classList.add('active')

        ApiFcardTv.post('cart', {
            'account': localStorage.getItem('account'),
            'pack': value[0].value
        }).then(response => {
            this.setState({
                selectedPlan: {
                    'name': value[0].name,
                    'plan': value[0].label, 
                    'value': new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value[0].amount),
                    'id': value[0].value,
                    'status': true
                }
            });

            localStorage.setItem('cart', response.data.data.id);
        }).catch(error => {
            alert('não deu  certo');
        });
    }

    addDays(days){

        let today = new Date();
        let date = today.setDate(today.getDate() + days);
        let newDate = new Date(date);

        let dateDay = newDate.getDate();
        let formatDay = dateDay < 10 ? '0' + dateDay : '' + dateDay;

        let dateMonth = newDate.getMonth() + 1;
        let formatMonth = dateMonth < 10 ? '0' + dateMonth : '' + dateMonth;

        let dateYear = newDate.getFullYear();

        return formatDay + '/' + formatMonth + '/' + dateYear;
    }

    render() {
        return(            
            <div className="register-form">
                <div className="register-box">
                    <div className="payment-form">
                        <step className="register-step">Passo 4 de 4</step> <br />
                        <div className="register-form-title">
                            Teste GRÁTIS por 10 dias
                        </div>
                        <step className="register-step pb-3">
                            Válido apenas para cartão de crédito ou débito <br />
                            Cancele quando quiser até de {this.addDays(10)} sem qualquer cobrança
                        </step>

                        <div className="mt-3">
                            <Select
                                options={this.state.plans}
                                color="#FFFFFF"
                                placeholder="Escolha o plano"
                                onChange={(value) => this.setSelectedPlan(value)}
                            />
                        </div>

                        <PaymentTabs />

                        {this.state.selectedPlan.status ? 
                            (
                                <Grid container justify="center">
                                <Grid item alignself="center" xs={10}>
                                    <div className="infoBox mt-3">
                                        <div className="infoBox-header d-flex justify-content-between">
                                            <p>Plano escolhido</p>
                                            <p>Valor</p>
                                        </div>
                                        <div className="infoBox-body d-flex justify-content-between">
                                            <p> { this.state.selectedPlan.name }  </p>
                                            <p> { this.state.selectedPlan.value } </p>
                                        </div>  
                                    </div>
                                </Grid>
                            </Grid>
                            ) : (
                                <></>
                            )
                        }

                        <Grid item xs={12}>
                            <p className="pt-3">
                                Ao clicar em “Concordar e assinar”, você está de acordo em iniciar a assinatura imediatamente e após o término do teste grátis que acaba em {this.addDays(10)}, a FutebolCard.TV cobrará a mensalidade de forma recorrente. Cancele quando quiser. 
                            </p>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentForm;