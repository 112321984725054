import React, { Component } from 'react';
import user  from '../../functions/user';
import Person from '../../components/Account/Person';
import AccountC from '../../components/Account/Account';
import Loading from '../../components/Loading/Loading';

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async validateToken() {
        return await user.tokenValidate();
    }

    componentDidMount() {
        this.validateToken().then(response => {
            this.setState({
                isLoading: false
            })
        }).catch(error => {
            this.props.history.replace('/login');
        });
    }

    render() {
        return(
            this.state.isLoading ? 
                (
                    <Loading size="2x" inverse="inverse"/>
                ) : (
                    <div className="profile">
                        <Person />
                        <AccountC />
                    </div>
                )
        )
    }

}

export default Account;