import React, { Component } from 'react';
import RecoverForm from '../../components/Password/RecoverForm';

class Recover extends Component
{
    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.replace('/home');
        }
    }

    render() {
        return(
            <div className="forgot-page">
                <RecoverForm />
            </div>
        );
    }
}

export default Recover;