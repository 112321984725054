import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ApiFcardTv from '../HttpRequest/ApiFcardTv';
import Loading from '../Loading/Loading';
import Ops from '../../components/Ops/Ops';
import mediaStreamPlayer from '../../functions/mediaStreamPlayer';
import './Player.scss';
import user from '../../functions/user';

import Add from '../../pages/MyList/add.svg';
import Check from '../../pages/MyList/check.png';

class Player extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLogged: false,
            isError: false,
            errMessage: null,
            title: '',
            description: '',
            time: 0,
            videoToken: null,
            videoId: null,
            videoThumbnail: null,
            videoInFavoriteList: false,
        };
    }

    async getToken() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return await ApiFcardTv.get('/token/' + this.props.match.params.id);
    }

    async getVideo() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return await ApiFcardTv.get('/video/' + this.props.match.params.id);
    }

    async getMyPlaylist() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return await ApiFcardTv.get('/user/playlist/' + localStorage.getItem('account'));
    }

    async addMyList(video, title, thumbnail) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        await user.addMyPlayList(video, title, thumbnail).then(response => {
           this.setState({videoInFavoriteList: true});
        }).catch(error => {
            return false;
        });
    }

    componentDidMount () {
        
        Promise.all([this.getToken(), this.getVideo(), this.getMyPlaylist()])
        .then(responses => {
            let duration = new Date(responses[1].data.data.data.duration * 1000).toISOString().substr(11, 8);
            this.setState({
                videoToken: responses[0].data.data.access_token,
                isLoading: false,
                title: responses[1].data.data.data.title,
                description: responses[1].data.data.data.description,
                time: duration,
                videoId: responses[1].data.data.data._id,
                videoThumbnail: responses[1].data.data.data.thumbnail 
            })

            responses[2].data.data[0].map(listVideo => {
                console.log(listVideo);
                if (listVideo.video_id === responses[1].data.data.data._id) {
                    console.log('entrei'+ responses[1].data.data.data._id);
                    this.setState({videoInFavoriteList: true});
                }
            });

            mediaStreamPlayer.mountPlayer(this.props.match.params.id, this.state.access_token);
        }).catch(error => {
            this.setState({isError: true});
        });

    }

    render() {
        return (
            this.state.isError ? (
                <Ops />
            ) :  (
                <div className="player-box">
                    {this.state.isLoading ? 
                        (
                            <Loading size="2x" inverse="inverse"/>
                        ) :  (
                            <>
                                <div className="player-center">
                                    <div className="video-container" id="mdstrm-player"></div>
                                    <videotitle className="player-title">{this.state.title}</videotitle>
                                    <description className="player-description">{this.state.description}</description>
                                    <span className="player-add">
                                        <p onClick={() => this.addMyList(this.state.videoId, this.state.title, this.state.videoThumbnail)}>
                                            <img src={this.state.videoInFavoriteList ? Check : Add} height="16"/>  MINHA LISTA
                                        </p>
                                    </span>
                                </div>
                            </>
                        ) 
                    }
                </div>
            )
        )
    }

}

export default withRouter(Player);