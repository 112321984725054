import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from "react-sidebar";
import './Header.scss';
import Logo from '../../Logo.svg';
import { withRouter, useLocation } from "react-router-dom";
import Profile from './profile-default.png'
import MenuIcon from '@material-ui/icons/Menu';
import Sidemenu from '../Sidemenu/Sidemenu';
import { NavigateBeforeSharp } from '@material-ui/icons';

class Header extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            hideTransparent: false,
            sidebarOpen: false
        };

        this.logout = this.logout.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

    }

    logout() {
        const cookies = localStorage.getItem('showCookies');
        localStorage.clear();
        localStorage.setItem('showCookies', cookies);
        this.props.history.push("/");
        window.location.reload();
    }

    onSetSidebarOpen(open) {
      this.setState({ sidebarOpen: open });
    }

    
    firstName(str){
        let firstName = str.split(' ').slice(0, -1).join(' ');
        return firstName;
    }


    render() {
        return(
            this.props.isLogged ?
            (
                <div className="header justify-content-between">
                    
                        <div className="header-links">
                            <Link to="/home"><img src={Logo} /></Link>
                        </div>

                        {this.props.isReady ?
                        (
                            <div className="d-none d-md-flex header-links text-center">
                                        <Link to="/home"><img className="icon" src="/icons/inicio.svg"/><p>Início</p></Link>
                                        <Link to="/packlist"><img className="icon" src="/icons/football.svg"/><p>Clubes</p></Link>
                                        <Link to="/search"><img className="icon" src="/icons/pesquisa.svg"/><p>Pesquisa</p></Link>
                                        <Link to="/my-list"><img className="icon" src="/icons/minha-lista.svg"/><p>Minha Lista</p></Link>
                                    </div>
                        ) : (
                            <div></div>
                        )}
                        

                        <div className="d-none d-md-flex header-links profile-link align-self-start justify-self-end pr-lg-4">
                            <div className="d-flex profile-link-item justify-content-between align-items-center  w-100">    
                                <Link className="text-capitalize" to="/home">{localStorage.getItem('name') ? localStorage.getItem('name') : (<div>&nbsp;</div>)}</Link>
                                <figure><img src={localStorage.getItem('photo') != 'null' ? localStorage.getItem('photo') : Profile} height="40" width="40" /></figure>
                            </div>
                            <div className="itens">
                                <Link to="/account">Conta</Link>
                                <p className="link pt-0" onClick={this.logout}>Sair</p>
                            </div>
                        </div>

                        <div className="d-flex d-md-none">
                            <Sidebar
                                sidebar={<Sidemenu />}
                                open={this.state.sidebarOpen}
                                onSetOpen={this.onSetSidebarOpen}
                                styles={{ root: { overflow: "inherit" }, sidebar: { width: "100%", maxWidth: "300px", background: "#111", color: "#fff", height: "100vh"  } }}
                            >
                            </Sidebar>

                            <div className="d-flex d-md-none text-center">
                                <button className="btn-menu-mobile" onClick={() => this.onSetSidebarOpen(true)}>
                                    <figure><img src={localStorage.getItem('photo') != 'null' ? localStorage.getItem('photo') : Profile} height="35" width="35" /></figure>
                                    {localStorage.getItem('name')}
                                </button>
                            </div>
                        </div>

                    
                </div>
            ) : (<>
                <div className="header justify-content-between">
                    
                    <div className="header-links">
                        <Link to="/home" className="logo"><img src={Logo} /></Link>
                    </div>

                    <div className="header-links justify-self-end text-right pr-1 pr-lg-5">
                        <Link  className="testLink" to="/register">Teste grátis</Link>
                        <Link  className="loginLink active" to="/login">Entrar</Link>
                    </div>
                
                </div>
            </>)
        );
    }
}

export default withRouter(Header);