import React, { Component } from 'react';
import Loading from '../Loading/Loading';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditIcon from '../Account/edit-icon.svg'
import toastr from 'toastr';
import Profile from '../Header/profile-default.png'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import user from '../../functions/user';
import './Avatar.scss';

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            modalTitle: 'Alterar foto',
            modalOpen: false,
            isLoading: false,
            isError: false,
            profilePic: '',
            disabled: true,
        };

        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        let profileImg = localStorage.getItem('photo') != 'null' ? localStorage.getItem('photo') : Profile

        this.setState({
            profilePic: profileImg
        })
    }

    handleSubmit(e) {

        e.preventDefault();
        
        this.setState({ isLoading: true })
        user.changePhoto(this.state.imagePreviewUrl).then(response => {
            
            console.log(response.data.ObjectURL);

            localStorage.setItem('photo', response.data.ObjectURL);
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
            toastr.clear();
            setTimeout(() => toastr.success(`Foto alterada com sucesso.`), 300);

            // Limpar as informações após o sucesso do PUT
            this.setState({
                modalOpen: false,
                file: '',
                imagePreviewUrl: '',
                isLoading: false,
                disabled: true,
            });

            document.location.reload(true);

        }).catch(error => {
            console.log(error)
            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }
            toastr.clear();
            setTimeout(() => toastr.error(error.message), 300);
        });

    }

    handleImageChange(e) {
        e.preventDefault();
        this.setState({ isLoading: true })

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
            file: file,
            imagePreviewUrl: reader.result,
            isLoading: false,
            disabled: false,
            });
        }

        reader.readAsDataURL(file)
    }

    
    handleClose(e) {
        
        this.setState({
            modalOpen: false,
            file: '',
            imagePreviewUrl: '',
            isLoading: false,
            disabled: true,
        });
    }

        
    render() {
        
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;

        if (imagePreviewUrl) {
            $imagePreview = (<img className="img-fluid" src={imagePreviewUrl} />);
        } else {
            $imagePreview = null;
        }
        
        return(
            <>
                {this.state.isLoading ? 
                    (
                        <Loading />
                    ) : (
                        <></>
                    )
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="error-modal"
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={this.state.modalOpen}>
                        <div className="modal-content">
                            <h2 id="transition-modal-title">{this.state.modalTitle}</h2>

                            <div className="previewComponent text-center d-flex justify-content-center align-items-center pt-4">


                            <div className="new-photo w-100">

                                <form onSubmit={(e)=>this.handleSubmit(e)}>

                                <label for="changeAvatar" className="changeAvatar">
                                    {$imagePreview ? (<></>) : (
                                        <>
                                            Clique aqui para escolher uma foto <br />
                                            <AddAPhotoIcon htmlColor="#444" fontSize="large" />
                                        </>
                                    )}
                                    <div className="imgPreview">
                                        {$imagePreview}
                                    </div>
                                </label>

                                <input
                                    id="changeAvatar" 
                                    className="fileInput" 
                                    type="file" 
                                    onChange={(e)=>this.handleImageChange(e)} />
                                </form>


                            </div>

                                <div className="atual-photo w-100">
                                    <p>Sua foto atual:</p>
                                    <figure className="img-round">
                                        <img src={this.state.profilePic} height="80" className="img-round" />
                                    </figure>
                                </div>
                                
                                
                            </div>
                            <button disabled={this.state.disabled} className="register-form-submit" onClick={(e)=>this.handleSubmit(e)}>Alterar foto</button>
                            <button className="light-button" onClick={this.handleClose}>Voltar</button>
                        </div>
                    </Fade>

                </Modal>
                <div className="profileBox--item flex">
                    <p>Alterar foto</p>
                    <a className="cursor-pointer" onClick={() => this.setState({modalOpen: true})} ><img height="28" src={EditIcon} /></a>
                </div>
            </>
        )
    }
}

export default Avatar;