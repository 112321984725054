import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import Logo from '../../Logo.svg';

class Header extends Component
{
    render() {
        return(
            <div className="footer d-flex w-100 justify-content-center flex-column">
                <div>
                    <img alt="footer-logo" src={Logo} height="30" />
                </div>
                <div>
                    <nav>
                        <ul>
                            <li><Link to="/legal-terms">Políticas de Privacidade</Link></li>
                            <li><Link to="/contact">Ajuda</Link></li>
                        </ul>
                    </nav>  
                </div>
                <div>
                    <p>
                        © FutebolCard Sistemas. Todos os direitos reservados.
                    </p>
                </div>
            </div>
        );
    }
}

export default Header;