import React, { Component } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';

class Login extends Component
{
    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.props.history.replace('/home');
        }
        
        const header = document.querySelector('.header');
        const testLink = document.querySelector('.testLink');

        if(testLink != null){
            header.classList.remove('transparent');
            testLink.classList.add('active');
        }
    
    }

    render() {
        return(
            <div className="login-page">
                <LoginForm />
            </div>
        );
    }
}

export default Login;