import ApiSsoFcard from '../components/HttpRequest/ApiFcardSso';
import ApiFcardTv from '../components/HttpRequest/ApiFcardTv';

class user {

    hasToken() {
        if (localStorage.getItem('token')) {
            return true;
        }
    
        return false;
    }

    tokenValidate() {
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiSsoFcard.get('/token_validate');
    }

    myPlayList() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/user/playlist/' + localStorage.getItem('account'));
    }

    addMyPlayList(video, title, thumbnail) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.post('/user/playlist', {
            'account': localStorage.getItem('account'),
            'video': video,
            'title': title,
            'thumbnail': thumbnail
        });
    }

    payWithSavedCreditCard(){
        
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.post('/payment/token', {
            'account_id': localStorage.getItem('account')
        });

    }

    deleteMyPlayList(video) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.delete('/user/playlist', {
            data: {
                'account': localStorage.getItem('account'),
                'video': video
            }
        });
    }

    insertPersonData(user, name, document, documentType) {
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiSsoFcard.put('user/' + user +  '/person', {
            'first_name': name,
            'last_name': name,
            'document': document,
            'document_type_id': documentType
        });
    }

    getPersonData(user) {
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiSsoFcard.get('/user/' + user + '/person');
    }

    getPurchasedPack() {
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/account/' + localStorage.getItem('account') + '/purchased_pack');
    }

    getTransactions() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/account/' + localStorage.getItem('account') + '/transactions');
    }

    getCardData() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/account/' + localStorage.getItem('account') + '/card');
    }

    changePassword(password, oldPassword) {
        ApiSsoFcard.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiSsoFcard.put('/user/password', {
            'user_id': localStorage.getItem('user'),
            'password': password,
            'old_password': oldPassword
        });
    }

    changePhoto(image){
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.put('/account/' + localStorage.getItem('account') + '/upload/avatar', {
            'file': image,
        });
    }

}

export default new user();