import React, { Component } from 'react';
import './ChangePasswordForm.scss';
import Loading from '../Loading/Loading';
import toastr from 'toastr';
import user from '../../functions/user';
import { withRouter } from 'react-router-dom';
class ChangePasswordForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            OldPassword: '',
            password: '',
            passwordConfirm: '',
            passwordStrength: 'pw-str',
            errorMessage: '',
        }

        this.formSubmit = this.formSubmit.bind(this);
    }

    handlePassword(event){
        this.setState({password: event.target.value});
        var score = 0
        var pass = event.target.value

        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i=0; i<pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        if (score > 80)
            return this.setState({passwordStrength: "pw-str strong"});
        if (score > 60)
            return this.setState({passwordStrength: "pw-str good"});
        if (score >= 30)
            return this.setState({passwordStrength: "pw-str weak"});
    
        return this.setState({passwordStrength: "pw-str"});;
    }

    /* API */
    formSubmit(e){
        e.preventDefault();
        this.setState({isLoading: true});

        if(this.state.password == this.state.passwordConfirm){
            user.changePassword(this.state.password, this.state.OldPassword).then(response => {
                this.setState({
                    isLoading: false
                });

                toastr.options = {
                    positionClass : 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 60000
                }
                toastr.clear();
                setTimeout(() => toastr.success(`Senha alterada com sucesso.`), 300);

                this.props.history.push('/account');

            }).catch(error => {
                this.setState({
                    isLoading: false,
                    errorMessage: error.response.data.data.message,
                });

                toastr.options = {
                    positionClass : 'toast-top-full-width',
                    hideDuration: 300,
                    timeOut: 60000
                }

                toastr.clear()
                setTimeout(() => toastr.success(`${this.state.errorMessage}`), 300)
                
            });
        }else{
            this.setState({
                isLoading: false,
            });

            toastr.options = {
                positionClass : 'toast-top-full-width',
                hideDuration: 300,
                timeOut: 60000
            }

            toastr.clear()
            setTimeout(() => toastr.error(`Confirmação de senha incorreta.`), 300)
        }
    }

    render() {
        return(
            <div className="login-form change-password">
                <div className="login-box">
                    <p className="login-form-title">Crie uma nova senha</p>
                    <small>A partir de agora esta será sua nova senha para acessar a Futebolcard.TV</small>

                    {this.state.isLoading ? 
                    (
                        <Loading />
                    ) : (
                        <form onSubmit={this.formSubmit}>
                            <input className="login-form-input" type="password" autoComplete="off" autoFocus value={this.state.OldPassword} onChange={(e) => this.setState({ OldPassword: e.target.value })} placeholder="Senha atual" />
                            <input className="login-form-input" type="password" autoComplete="off" value={this.state.password} onChange={(e) => this.handlePassword(e)} placeholder="Nova senha" />
                            <input className="login-form-input" type="password" autoComplete="off" value={this.state.passwordConfirm} onChange={(e) => this.setState({ passwordConfirm: e.target.value })} placeholder="Confirme sua nova senha" />
                            <div className={this.state.passwordStrength}></div>
                            <div className="pw-type">
                                <div>Baixa</div>
                                <div>Média</div>
                                <div>Alta</div>
                            </div>
                            <p>
                                Use no mínimo seis caractéres com distinção entre maiúsculas e minúsculas e ao menos um número ou caractére especial.
                            </p>
                            <input type="submit" className="register-form-submit" value="Trocar senha" />
                        </form>
                    )}
                
                </div>
            </div>
        )
    }

}

export default withRouter(ChangePasswordForm);