import React, {Component} from 'react';
import PaymentForm from '../../components/PaymentForm/PaymentForm';
import user  from '../../functions/user';
import purchasedPack from '../../functions/purchasedPack';
import Loading from '../../components/Loading/Loading';

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    async componentDidMount() {
        await purchasedPack.purchasedPackValidate()
        .then(response => {
            if (response.data.data.status) {
                this.props.history.replace('/home');
            }

            this.setState({ isLoading: false });
        })
        .catch(error => {
            if (error.response.status === 404) {
                this.setState({ isLoading: false });
            }
        })
    }
    
    render() {
        return(
            <div className="payment-page">
                {this.state.isLoading ? 
                    (
                        <Loading size='3x' inverse='inverse' />
                    ) : (
                        <PaymentForm />
                    )
                }   
            </div>
        );
    }
}

export default Register;