import React, { Component } from 'react'
import LoadingInner from '../../components/LoadingInner/LoadingInner'
import VideosList from '../../components/VideosList/VideosList'
import Videos from '../../functions/videos';
import User from '../../functions/user';
import './Search.scss'


class Search extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            videoList: [],
            filter: '',
            filterList: [],
            disabled: "",
            timeout: 0,
        }

        this.filterWords = this.filterWords.bind(this);
    }

    purchasedPack() {
        return User.getPurchasedPack();
    }

    filterWords(e){
        this.setState({
            filter: e.target.value,
        });

        if(this.state.timeout) clearTimeout(this.state.timeout);
        
        this.state.timeout = setTimeout(() => {

            if (e.target.value.length < 3) {
                this.setState({
                    filterList: [],
                    filtredArray: []
                });
    
                return;
            }
    
            this.setState({
                filtredArray: [],
                isLoading: true,
                disabled: 'disabled',
            });
    
            this.purchasedPack().then(response => {
                console.log(response);
                Videos.searchVideos(this.state.filter, response.data.data.pack_data.tag)
                .then(searchResponse => {
                    let filtredArray = [];
                    this.setState({
                        videoList: searchResponse.data.data.videos,
                    });
        
                    for (let i = 0; i < this.state.videoList.length; i++) {    
                        filtredArray.push(this.state.videoList[i]);
                    }
            
                    this.setState({
                        filterList: filtredArray,
                        isLoading: false,
                        disabled: '',
                    });
        
                    document.getElementById("search").focus();
        
                }).catch(error => {
                    this.setState({
                        isLoading: false,
                        disabled: '',
                    });
                });
            });

        }, 300);

        

    }

    componentDidMount() {
        document.getElementById("search").focus();
    }

    render(){
        return(
            <div>
                <div className="page">

                <div className="Search-filter">
                    <input type="text" id="search" value={this.state.filter} onChange={(e) => this.filterWords(e)} placeholder="Time, título ou categoria" />
                </div>

                    {!this.state.isLoading ?
                        (
                            this.state.filter.length >= 3 ? (
                                this.state.filterList.length < 1 ? ( 
                                    <div>
                                        <h2>Nenhum resultado encontrado para "{this.state.filter}".</h2>
                                        <p>Tente pesquisar por nome do vídeo, time ou categoria.</p>
                                    </div>
                                    ) : ( 
                                        <VideosList list={this.state.filterList} buttons="false"/>
                                    )
                            ) : (<></>)
                    ) : (
                        <LoadingInner size="2x" inverse="inverse"/>
                    )}

                </div>
            </div>
        )
    }
}

export default Search;