class mediaStreamPlayer {

    mountPlayer(videoId, accessToken) {
        let playerOptions = {
            width: 880, // Width in pixels
            height: 500, // Height in pixels
            type: 'media', 
            id: videoId, 
            access_token: accessToken,
            autoplay: true,
            player: '602d53c3b68bf150039fdc11', 
            events: { 
                onPlayerReady: function() { // Optional callback to be triggered as soon as the player has finished loading
                    console.log("Player is ready");
                },
                onPlay: function() { // Optional callback to be triggered as soon as the player starts playing
                    console.log("Playing...");
                },
                onVideoEnd: function() { // Optional callback to be triggered when the video ends playing
                    console.log("Video just ended");
                },
                onVideoStop: function() { // Optional callback to be triggered when the user stops or pauses the video
                    console.log("User stopped or paused the video");
                },
                onVideoError: function() { // Optional callback to be triggered when there's a playback error
                    console.log("There was an error while loading the video");
                },
                onVolumeChange: function(volume) { // Optional callback to be triggered when volume is changed (0-100)
                    console.log("Volume was changed to " + volume);
                },
                onTimeUpdate: function(time) { // Optional callback to be triggered when time is updated
                    console.log("Current time is " + time);
                },
                onFullscreenChange: function(fullscreen) { // Optional callback to be triggered when fullscreen status change
                    console.log("Is fullscreen " + fullscreen);
                }
            }
        }

        let player = new window.MediastreamPlayer("mdstrm-player", playerOptions);
    
        player.videoPlay()
    }

}

export default new mediaStreamPlayer();