import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Loading.scss';

const Loading = (props) => {
    return (
        <div class="loading-stage">
            <div className="loading-frame">
                {props.inverse === 'inverse' ?
                (<FontAwesomeIcon icon={faSpinner} spin inverse size="4x" />) :
                (<FontAwesomeIcon icon={faSpinner} spin size="4x" />)
                }
            </div>
        </div>
    ) 
}

export default Loading;

