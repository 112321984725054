import React, { Component } from 'react';
import { Link } from  'react-router-dom';

class Error extends Component
{
    render() {
        return(
            <div>
                <h2>Ooopsss!!!!</h2><br />
                <h3>Algo deu errado</h3>  <br />
                <Link to="/home">Voltar para o inicio</Link>
                <hr />
            </div>
        );
    }
}

export default Error;