import React, { Component } from 'react';
import './CategoryCarousel.scss';
import Slider from 'react-slick';
import ApiFcardTv from '../HttpRequest/ApiFcardTv';
import Loading from '../Loading/Loading';
import { withRouter, Link } from "react-router-dom";
import Ops from '../../components/Ops/Ops';
import user from '../../functions/user';

import Add from '../../pages/MyList/add.svg';
import Check from '../../pages/MyList/check.png';

import LockIcon from '@material-ui/icons/Lock';


class CategoryCaorousel extends Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoading: true,
            cardsList: [],
            categoryName: null,
            isLogged: false,
            isError: false,
            errMessage: null,
            myListIcon: []
        };

        this.addMyList  = this.addMyList.bind(this);
    }

    async addMyList(video, title, thumbnail) {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        await user.addMyPlayList(video, title, thumbnail).then(response => {
            document.getElementById(video).src = Check;
        }).catch(error => {
            return false;
        });
    }



    async componentDidMount() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        ApiFcardTv.get('video/playlist/' + this.props.playList +'/' + localStorage.getItem('account'))
        .then(response => {
            this.setState({
                cardsList: response.data.data.medias,
                categoryName: response.data.data.name,
                isLoading: false
            });
            
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isError: true,
            });
        });
    }

    formatText(text) {
        let str = text.split('-');
        let response;
        str.map(value => {
            response = <>{response}{value}<br /></> 
        });

        return response;
    }

    render() {
        const settings = {
            slidesToShow: 5,
            slidesToScroll: 2,
            speed: 700,
            infinite: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false
                    }
                }
            ]
        };

        console.log('Card list atual: ' + this.state.cardsList)

        return(
            this.state.isError ? (
                <></>
            ) : (
                this.state.isLoading ? (
                        <Loading size="2x" inverse="inverse"/>
                    ) : (
                        this.state.cardsList.length > 0 ? (
                            <div className="categories-carousel">
                                <p className="category-title">{this.state.categoryName}</p>
                                <Slider {...settings}>
                                    { this.state.cardsList.map(card => {
                                        return (
                                            
                                            <div className="carousel-card">
                                                    
                                                    {this.props.purchased ? (
                                                        <Link to={"/video/" + card._id} >
                                                            <img alt="video-thumbnail" className="img-carousel" src={card.thumbnail} />
                                                        </Link>
                                                    ) : (
                                                        <Link to={"/upgrade-pack"} >
                                                            <figure>
                                                                <LockIcon fontSize="large" />
                                                                <img alt="video-thumbnail" className="img-carousel" src={card.thumbnail} />
                                                            </figure>
                                                        </Link>
                                                    )} 
                                                    <div className="carousel-infos">
                                                        <titleSlide className="carousel-title"><span>{this.formatText(card.title)}</span></titleSlide>
                                                        <titleSlide className="carousel-description"><span>{this.formatText(card.description)}</span></titleSlide>
                                                    </div>
                                                    {this.props.purchased ? (
                                                        <p onClick={() => this.addMyList(card.id, card.title, card.thumbnail)}><img id={card.id} src={card.my_list ? Check : Add} height="14" /> Minha lista</p>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                        )
                                    })} 
                                </Slider>
                            </div>
                        ) : (
                            <></>
                        )
                    )
                )   
            );
    }
}

export default withRouter(CategoryCaorousel);