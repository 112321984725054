import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './ErrorModal.scss';


export default function TransitionsModal({ children }) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="error-modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-content">
            <h2 id="transition-modal-title">Na trave!!!</h2>
            <p id="transition-modal-description">{children}</p>
            <button className="close-btn" onClick={handleClose}>Fechar</button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}