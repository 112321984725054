class registerFormValidator {

    validate(login, password, repeatPassword) {
        const passValidate = this.validatePassword(password, repeatPassword); 

        if (!passValidate.status) {
            return { status: false, message: passValidate.message }
        }

        return { status:true }
    }

    validatePassword(password, repeatPassword) {
        if (password !== repeatPassword) {
            return {
                status: false,
                message: 'Os campos "Senha" e "Confirme sua senha" devem ser iguais.'
            };
        }
        
        if (password.length < 8) {
            return {
                status: false,
                message: 'A senha deve conter no mínimo 8 caracteres.'
            };
        }

        return {status: true};
    }

}

export default new registerFormValidator();