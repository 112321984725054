import React, {Component} from 'react';
import ContractForm from '../../components/ContractForm/ContractForm';
import user from '../../functions/user';

class Contract extends Component {

    async componentDidMount() {
        user.tokenValidate()
        .then(response => {
            return;
        })
        .catch(error => {
            this.props.history.replace('/')
        })
    }
    
    render() {
        return(
            <div className="payment-page">
                <ContractForm />
            </div>
        );
    }
}

export default Contract;