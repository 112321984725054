import ApiFcardTv from '../components/HttpRequest/ApiFcardTv';

class purchasedPack {

    purchasedPackValidate() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/purchased_pack/validate/' + localStorage.getItem('account'));
    }

    getPurchasedPack() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/account/' + localStorage.getItem('account') + '/purchased_pack');
    }

    validatePurchasedPackUpdate() {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.get('/account/' + localStorage.getItem('account') + '/purchased_pack/validate');
    }

    changePurchasedPack()  {
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.put('/account/purchased_pack/update', {
            'account_id': localStorage.getItem('account')
        });
    }

    cancelPurchasedPack(){
        ApiFcardTv.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        return ApiFcardTv.put('/account/purchased_pack/cancel', {
            'account_id': localStorage.getItem('account')
        });
    }

}

export default new purchasedPack();