import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './BannerCarousel.css';

class BannerCarousel extends Component {
    render() {
        return(
            <div className="banner-carousel">
                <Carousel>
                    <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100 "
                            src="/img/banners/banner01.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100"
                            src="/img/banners/banner02.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="d-block w-100"
                            src="/img/banners/banner03.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default BannerCarousel;