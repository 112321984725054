import React, { Component } from 'react';
import purchasedPack  from '../../functions/purchasedPack';
import user from '../../functions/user';
import './Person.scss'
import EditIcon from './edit-icon.svg'
import { Link } from 'react-router-dom';

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isLoading: false,
            purchasedPack: [],
            transactions: []
        }

        this.getAccountTransactions.bind(this);
    }

    getAccountTransactions() {
        user.getAccountTransactions().then(response => {
            this.setState({transactions: response.data.data});
        }).catch(error => {

        });
    }

    translatePurchasedPackStatus(status) {
        switch (status) {
            case 'paid':
                return 'Pago';
            case 'trial':
                return 'Degustação';
            case 'canceled':
                return 'Cancelado'; 
            default:
                return 'Não pago'
        }
    }

    componentDidMount() {
        purchasedPack.getPurchasedPack().then(response => {
            response.data.data.status = this.translatePurchasedPackStatus(response.data.data.status);
            
            this.setState({purchasedPack: response.data.data});
        }).catch(error => {

        });
    }

    render() {
        return (
            <div>
                <div className="profileBox">
                    <div className="profileBox--header">
                        Detalhes do plano
                    </div>
                    <div className="profileBox--item flex">
                        Plano: {this.state.purchasedPack.name} 
                        <Link to="/upgrade-pack"><img height="28" src={EditIcon} /></Link>
                    </div>
                    <div className="profileBox--item">
                        Valido até:  {this.state.purchasedPack.validity_end}
                    </div>
                    <div className="profileBox--item flex">
                        Forma de pagamento: Cartão de crédito {this.state.purchasedPack.recurrence ? ('recorrente') : ('') }
                        <Link to="/change-payment"><img height="28" src={EditIcon} /></Link>
                    </div>

                    <div className="profileBox--item">
                        Histórico de cobrança 
                    </div>
                    <div className="profileBox--item">
                        Status do plano: {this.state.purchasedPack.status}
                    </div>
                </div>

                <br />
            </div>
        )
    }

}

export default Account;

