import React, {Component} from 'react';
import { Container, Grid } from '@material-ui/core';
import user  from '../../functions/user';
import Logo from '../../pages/Landing/logo.svg'
import { Link } from 'react-router-dom';

class Reactivate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    render() {
        return(
            <div className="reactivate-page Landing">
                <div className="Landing-reactivate">
                    <div className="align-center">
                        <Grid container justify="flex-end" spacing={0}>
                            <Grid item sm={12} md={8} lg={7}>
                                <div className="animatedScenario" id="animatedScenario">
                                    <img src={Logo} height="90" />
                                    <h2>
                                        Você não é mais assinante.
                                    </h2>
                                    <h3>
                                        Vibre de qualquer lugar a todo momento.
                                    </h3>
                                    <br />
                                    <div className="animatedScenario-plans">
                                        <h4>
                                            Planos a partir de
                                        </h4>
                                        <h2>
                                            R$ 9,90 <small>/mês</small>
                                        </h2>
                                        <Link to="/change-payment">
                                            <a className="btn btn-primary">
                                                REATIVAR ASSINATURA
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}
export default Reactivate;