import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Sidemenu.scss'

class Sidemenu extends Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/login");
        window.location.reload();
    }

    render(){
        return (
            <div className="sidemenu">
                <h2>Menu</h2>
                <ul>
                    <li><Link to="/">Início</Link></li>
                    <li><Link to="/packlist">Clubes</Link></li>
                    <li><Link to="/search">Pesquisa</Link></li>
                    <li><Link to="/my-list">Minha lista</Link></li>
                    <li><Link to="/account">Conta</Link></li>
                    <li onClick={this.logout}><Link>Sair</Link></li>
                </ul>
            </div>
        )
    }
}

export default Sidemenu